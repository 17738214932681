<template>
  <div class="login hidden">
    <app-header/>
    <div class="page">
      <section class="text-start">
        <div class="tab-box">
          <div class="component-box">
            <ActionLogout :sourceURL="getServiceUrl('logout')" :dontShowImmediately="false"></ActionLogout>
          </div>
        </div>
      </section>
      <vuedal></vuedal>
    </div>
    <app-footer/>
  </div>
</template>

<script lang="ts">

import mixins from 'vue-typed-mixins'
import MXPageGeneral from '@/components/mixins/pageGeneral'
import { generalService } from '@/scripts/services/GeneralService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import ActionLogout from '@/components/ActionLogout.vue' // @ is an alias to /src

export default mixins(MXPageGeneral).extend({
  name: 'Logout',
  components: {
    ActionLogout,
  },
  mounted (): void {
    generalService.log('View "PageLogout.vue" mounted !')
  },
  created (): void {
    this.$nextTick((): void => {
      generalService.log('View "PageLogout.vue" created')
      this.$on('afterSendActionData', (data: {[key: string]: any}) => {
        generalService.log('View "PageLogin.vue" - afterSendActionData', data)
        localStorage.removeItem('userAvatarPicture')
        this.$store.commit('resetUserData')
        this.$router.replace({ name: 'login', params: {} })
      })
      vueTemplateService.$emit4AllParents(this, 'setViewPortMode', 'default')
    })
  }
})
</script>
