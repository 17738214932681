<template>
  <header v-if="!isIFrame()" v-cloak>
    <div class="navbar navbar-static-top">
      <div class="d-inline-flex">
      </div>

      <div class="d-inline-flex">
        <div v-if="isAuthenticated && !isLoginPage" class="ms-2">
          <a href="javascript:void(0)" class="dropdown-item" v-bind:title="'Notifications'" @click="showNotifications()"><span class="icon notifications-small" :class="{count: feedCount}" :data-count="feedCountString"></span></a>
        </div>
        <div class="ms-2">
          <a href="javascript:void(0)" class="dropdown-item" @click="personSearch()">
            <span class="icon person-search"></span>
          </a>
        </div>
        <div v-if="!isAuthenticated && !isLoginPage" class="ms-2">
          <router-link v-bind:to="{name: 'digestRegistration'}" class="dropdown-item" v-bind:title="headerLabels.item.registration">
            <span class="icon add-person"></span>
          </router-link>
        </div>
        <div v-if="!isAuthenticated && !isLoginPage" class="ms-2">
          <router-link v-bind:to="{name: 'login'}" class="dropdown-item" v-bind:title="headerLabels.item.login">
            <span class="icon login"></span>
          </router-link>
        </div>
        <div v-if="isAuthenticated && !isLoginPage" class="dropdown ms-2">
          <a href="#topSubNavigation" data-bs-toggle="dropdown" id="dropdownMenuButtonTopSubNavigation" v-bind:title="userName" >
            <span v-if="avatarPicture !== ''" class="avatar-picture"><img :src="avatarPicture" ></span>
            <span v-if="avatarPicture == ''" class="avatar-icon"><span><strong>{{ userName.substring(0,1).toUpperCase() }}</strong></span></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButtonTopSubNavigation">
            <li>
              <router-link v-bind:to="{name: 'dashboard'}" class="dropdown-item" v-bind:title="headerLabels.item.home">
                <span class="icon home" v-html="headerLabels.item.home"></span>
              </router-link>
            </li>
            <li>
              <router-link v-bind:to="{name: 'account'}" class="dropdown-item" v-bind:title="headerLabels.item.settings">
                <span class="icon settings" v-html="headerLabels.item.settings"></span>
              </router-link>
            </li>
            <li>
              <router-link v-bind:to="{name: 'logout'}" class="dropdown-item" v-bind:title="headerLabels.item.logout + ' (' + userName + ')'">
                <span class="icon logout" v-html="headerLabels.item.logout"></span>
              </router-link>
            </li>
          </ul>
        </div>        
      </div>
    </div>
  </header>  
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { globalVar } from '@/scripts/own/_globalVar'
import { generalService } from '@/scripts/services/GeneralService'
import { uiService } from '@/scripts/services/UIService'
import DatasourcePersonSearch from '@/components/DatasourcePersonSearch.vue'
import DatasourceNotifications from '@/components/DatasourceNotifications.vue'

export default Vue.extend({
  name: 'appHeader',
  props: {
    user: Object,
    tree: Array as PropType<{[key: string]: any}>,
    logout: Object
  },
  data (): any {
    return {
      avatarPicture: String,
      feedCount: Number
    }
  },
  created (): void {
    generalService.log('View "header.vue" created')
    
    const userAvatarPicture = localStorage.getItem('userAvatarPicture')
    this.avatarPicture = userAvatarPicture ? userAvatarPicture : ''
    this.feedCount = 0

    this.$on('updateAvatarPicture', (avatarPicture: string): void => {
      this.avatarPicture = avatarPicture
    })
    
    this.$on('updateFeedState', (state: {[key: string]: number}): void => {
      this.feedCount = state.count
    })
  },
  mounted (): void {
    generalService.log('View "header.vue" mounted !')
    this.setContactReferrer()
    generalService.notificationsLocator(this)
  },
  updated (): void {
    this.$nextTick((): void => {
      generalService.log('View "header.vue" updated', this.tree)
    })
  },
  computed: {
    isAuthenticated (): boolean {
      return this.$store.state.user.authenticated
    },
    headerLabels: function(): {[key: string]: any} {
      return globalVar.header.labels
    },
    userName: function(): string {
      return this.$store.state.user.nickName
    },
    isLoginPage (): boolean {
      return this.$router.currentRoute.name === 'root' || this.$router.currentRoute.name === 'index' || this.$router.currentRoute.name === 'login'
    },
    feedCountString (): string {
      if (this.feedCount >= 1000) return Math.round(this.feedCount/1000).toString() + 'k'
      else return this.feedCount.toString()
    }
  },
  methods: {
    isPageActive (pagePath: string):boolean {
      return (this.$router.currentRoute.path.match(new RegExp(pagePath)) !== null)
    },
    isIFrame () {
      return generalService.isIFrame()
    },
    isItemActive (name: string): boolean {
      return this.$router.currentRoute.name === name
    },
    getLogoImage (): string {
      return globalVar.appEngine.images.logoBeta
    },
    setContactReferrer (): void {
      if (this.$router.currentRoute.path !== '/help/contact') sessionStorage.setItem('contactReferrer', window.location.href)
    },
    personSearch (): void {
      Vue.component('DatasourcePersonSearch', DatasourcePersonSearch)
      const sourceURL = globalVar.serviceURL.users
      if (sourceURL) {
        uiService.showViewInPopup('DatasourcePersonSearch', sourceURL, {executeDirectly: false}, {title: this.headerLabels.searchForm.placeholder}, this, 'md')
      }
    },
    showNotifications (): void {
      Vue.component('DatasourceNotifications', DatasourceNotifications)
      const sourceURL = globalVar.serviceURL.notifications
      if (sourceURL) {
        this.feedCount = 0
        uiService.showViewInPopup('DatasourceNotifications', sourceURL, {executeDirectly: false}, {}, this, 'md')
      }
    }
  }
})
</script>
