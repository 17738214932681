<template>
  <fragment>
    <fragment v-if="isAuthenticated">
      <fragment v-if="friendshipLayout.icon">
        <a v-if="friendshipLayout.cancelFriendshipButton"  href="javascript:void(0)" class="btn btn-secondary friendship-decline" v-bind:title="labels.dialog.pending.button.decline" @click="(e) => cancelFriendship(false)"><i class="icon decline"></i></a>
        <a v-if="friendshipState == 'PENDING'" href="javascript:void(0)" class="btn btn-primary friendship-approve" v-bind:title="labels.dialog.pending.button.approve" @click="(e) => acceptFriendship(false)"><i class="icon approve"></i></a>
      </fragment>
      <fragment v-else>
        <a v-if="friendshipState == 'ACCEPTED'" href="javascript:void(0)" class="btn btn-secondary friendship-accepted" v-bind:title="labels.tooltip.accepted" @click.stop="cancelFriendship()"><span class="on-default" v-html="labels.button.friendship"></span><span class="on-hover" v-html="labels.button.cancelFriendship"></span></a>
        <a v-if="friendshipState == 'ABSENT'" href="javascript:void(0)" class="btn btn-secondary request-friendship" v-bind:title="labels.tooltip.absent" @click.stop="requestFriendship()"><span v-html="labels.button.addFriend"></span></a>
        <a v-if="friendshipState == 'PENDING'" href="javascript:void(0)" class="btn btn-secondary friendship-pending" v-bind:title="labels.tooltip.pending" @click.stop="approveFriendship()"><span v-html="labels.button.acceptFriendship"></span></a>
        <a v-if="friendshipState == 'WAITING'" href="javascript:void(0)" class="btn btn-secondary friendship-waiting" v-bind:title="labels.tooltip.waiting" @click.stop="deleteInvitation()"><span class="on-default" v-html="labels.button.waitingFriendship"></span><span class="on-hover" v-html="labels.button.cancelFriendshipRequest"></span></a>
        <a v-if="friendshipState == 'CANCELLED'" href="javascript:void(0)" class="btn btn-secondary friendship-cancelled" v-bind:title="labels.tooltip.cancelled"><span v-html="labels.button.canceledFriendship"></span></a>
      </fragment>
    </fragment>
    <fragment v-else>
      <a href="javascript:void(0)" class="btn btn-secondary request-friendship" v-bind:title="labels.tooltip.absent" @click="loginAndRequestFriendship()"><span v-html="labels.button.addFriend"></span></a>
    </fragment>
  </fragment>
</template>

<script lang="ts">
import Vue from 'vue'
import jQuery from 'jquery'
import '@/scripts/own/jquery.string'
import { Bus as VuedalsBus } from '@/components/vuedals/main'
import { globalVar } from '@/scripts/own/_globalVar'
import { uiService } from '@/scripts/services/UIService'
import { dataService } from '@/scripts/services/DataService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'

export default Vue.extend({
  name: 'friendship-control',
  props: {
    layout: Object,
    state: Object
  },
  mounted (): void {
    this.$nextTick((): void => {
      if (sessionStorage.getItem('requestFriendship') === 'true') {
        sessionStorage.removeItem('requestFriendship')
        switch (this.friendshipState) {
          case "ABSENT": {
            this.requestFriendship()
            break
          }
          case "WAITING": {
            const content = '<div class="text-center"><p>' + this.labels.popup.waiting.text + '</p></div>'
            uiService.showMessage(this.labels.popup.waiting.headline, content, () => {/**/}, 'md')
            break
          }
          case "ACCEPTED": {
            const content = '<div class="text-center"><p>' + this.labels.popup.accepted.text + '</p></div>'
            uiService.showMessage(this.labels.popup.accepted.headline, content, () => {/**/}, 'md')
            break
          }
        }
      }
      this.$on('afterChangeDatasource', (e: any): void => {
        if (e.successfully && (e.data.originId === 'cancelFriendship' || e.data.originId === 'acceptFriendship' || e.data.originId === 'requestFriendship')) {
          if (typeof this.state.value.id !== 'undefined') {
            e.data.params['id'] = this.state.value.id.value
          }
          vueTemplateService.$emit4AllParents(this, 'afterChangeDatasource', e)
        }
      })
    })
  },
  computed: {
    friendshipState (): string {
      return this.state && typeof this.state.value !== 'undefined' ? this.state.value.state.value : ''
    },
    friendshipLayout (): any {
      return this.layout && typeof this.layout.value !== 'undefined' ? this.layout.value : {icon: false, cancelFriendshipButton: false}
    },
    isAuthenticated (): boolean {
      return this.$store.state.user.authenticated
    },
    labels (): {[key: string]: any} {
      return globalVar.view.default.labels.friendshipControl
    }
  },
  methods: {
    getServiceUrl (sourceId: string): string {
      return dataService.getServiceUrl(this, sourceId)
    },
    requestFriendship (): void {
      const sourceURL = this.getServiceUrl('requestFriendship')
      if (sourceURL) {
        const dialogContent = {
          title: (typeof globalVar.view['voyoa_requestFriendship_friendshipEvents'] !== 'undefined') && (typeof globalVar.view['voyoa_requestFriendship_friendshipEvents'].labels !== 'undefined') ? globalVar.view['voyoa_requestFriendship_friendshipEvents'].labels.headline : 'Request friendship',
          body: ''
        }
        uiService.showViewInPopup('ActionRequestFriendship', sourceURL, {nickName: this.state.value.nickName.value, executeDirectly: false}, dialogContent, this, 'md')
      }
    },
    loginAndRequestFriendship (): void {
      sessionStorage.setItem('redirectUrl', this.$router.currentRoute.fullPath)
      sessionStorage.setItem('requestFriendship', 'true')
      this.$router.push({name: 'login', params: {}})
    },
    approveFriendship (): void {
      const message = this.state.value.message.value ? '<div class="mb-3">'+ this.labels.dialog.pending.message +' <i>' + this.state.value.message.value + '</i></div>' : ''
      let content = '<div>' + this.labels.dialog.pending.text + '</div>' + message
      content = jQuery.string.replacePlaceHolders(content, {
        initiatorNickName: this.state.value.nickName.value
      })
      uiService.showModal({
        title: this.labels.dialog.pending.headline,
        content: content,
        footer: {
          buttons: [
            {
              label: this.labels.dialog.pending.button.approve,
              class: 'btn-primary',
              action: ():void =>  {
                VuedalsBus.$emit('close')
                const sourceURL = this.getServiceUrl('acceptFriendship')
                if (sourceURL) {
                  uiService.showViewInPopup('ActionPopup', sourceURL, {nickName: this.state.value.nickName.value, executeDirectly: true}, {}, this, 'sm hidden')
                }
              }
            },
            {
              label: this.labels.dialog.pending.button.decline,
              class: 'btn-primary',
              action: ():void =>  {
                VuedalsBus.$emit('close')
                const sourceURL = this.getServiceUrl('cancelFriendship')
                if (sourceURL) {
                  uiService.showViewInPopup('ActionPopup', sourceURL, {nickName: this.state.value.nickName.value, executeDirectly: true}, {}, this, 'sm hidden')
                }
              }
            },
            {
              label: this.labels.dialog.pending.button.cancel,
              class: 'btn-secondary',
              action: ():void => {
                VuedalsBus.$emit('close')
              }
            }
          ]
        }
      })
    },
    acceptFriendship (dialog?:boolean): void {
      const sourceURL = this.getServiceUrl('acceptFriendship')
      if (sourceURL) {
        const dialogContent = {
          title: (typeof globalVar.view['voyoa_acceptFriendship_friendshipEvents'] !== 'undefined') && (typeof globalVar.view['voyoa_acceptFriendship_friendshipEvents'].labels !== 'undefined') ? globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels.default.headline : 'Cancel friendship',
          body: (typeof globalVar.view['voyoa_acceptFriendship_friendshipEvents'] !== 'undefined') && (typeof globalVar.view['voyoa_acceptFriendship_friendshipEvents'].labels !== 'undefined') ? globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels.default.text : 'Are you sure?'
        }
        uiService.showViewInPopup('ActionPopup', sourceURL, {nickName: this.state.value.nickName.value, executeDirectly: dialog ? false : true}, dialog ? dialogContent : {}, this, 'sm' + !dialog ? ' hidden': '')
      }
    },
    cancelFriendship (dialog?:boolean): void {
      const sourceURL = this.getServiceUrl('cancelFriendship')
      if (sourceURL) {
        dialog = typeof dialog === 'undefined' ? true : dialog
        const dialogContent = {
          title: (typeof globalVar.view['voyoa_cancelFriendship_friendshipEvents'] !== 'undefined') && (typeof globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels !== 'undefined') ? globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels.default.headline : 'Cancel friendship',
          body: (typeof globalVar.view['voyoa_cancelFriendship_friendshipEvents'] !== 'undefined') && (typeof globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels !== 'undefined') ? globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels.default.text : 'Are you sure?'
        }
        uiService.showViewInPopup('ActionPopup', sourceURL, {nickName: this.state.value.nickName.value, executeDirectly: dialog ? false : true}, dialog ? dialogContent : {}, this, dialog ? 'sm' : 'sm hidden')
      }
    },
    deleteInvitation (): void {
      const sourceURL = this.getServiceUrl('cancelFriendship')
      if (sourceURL) {
        const dialogContent = {
          title: (typeof globalVar.view['voyoa_cancelFriendship_friendshipEvents'] !== 'undefined') && (typeof globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels !== 'undefined') ? globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels.deleteInvitation.headline : 'Cancel friendship',
          body: (typeof globalVar.view['voyoa_cancelFriendship_friendshipEvents'] !== 'undefined') && (typeof globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels !== 'undefined') ? globalVar.view['voyoa_cancelFriendship_friendshipEvents'].labels.deleteInvitation.text : 'Are you sure?'
        }
        uiService.showViewInPopup('ActionPopup', sourceURL, {nickName: this.state.value.nickName.value, executeDirectly: false}, dialogContent, this, 'sm')
      }
    }
  }
})
</script>
