<template>
  <div v-if="id != null" class="action wrapper" v-bind:class="{loading: showLoader}">
    <form v-if="fieldsEnabled" v-bind:action="executeUrl" v-bind:name="id" v-bind:id="formId" enctype="multipart/form-data" class="form-framework" v-bind:data-locale="locale" v-bind:data-sourceid="id" data-send-method="ajax" v-bind:data-hide-loader="hideLoader">
      <div class="modal-body">
        <fieldset>
          <div class="row">
            <div class="col-12 mb-3" v-html="introText"></div>
          </div>
          <div class="row">
            <f-field v-bind:field="field.message" v-bind:cell-size="'12'"></f-field>
          </div>
        </fieldset>
        <div v-for="(value, key) in params" v-bind:key="value">
          <input type="hidden" v-bind:name="key" v-bind:value="value">
        </div>
      </div>
      <div v-if="labelsEnabled" class="modal-footer pb-0">
        <button type="button" class="btn btn-secondary" @click="cancel" v-html="labels.button.cancel"></button>
        <button type="submit" class="btn btn-primary" v-html="labels.button.submit"></button>
      </div>
      <input type="hidden" name="form_action" :value="originId">
    </form>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXActionPopup from '@/components/mixins/actionPopup'
import jQuery from 'jquery'
import '@/scripts/own/jquery.string'
import fField from '@/components/form/f-field.vue'
import ContentErrorMessage from '@/components/_content_error_message.vue'

export default mixins(MXActionPopup).extend({
  name: 'ActionRequestFriendship',
  components: {
    fField,
    ContentErrorMessage
  },
  computed: {
    introText (): string {
      const nickName = typeof this.params.nickName !== 'undefined' ? this.params.nickName : null
      if (nickName) {
        return jQuery.string.replacePlaceHolders(this.labels.text, {
          nickName: nickName
        })
      } else {
        return ''
      }
    }
  }
})
</script>
