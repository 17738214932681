<template>
  <div v-if="id != null" class="action wrapper" v-bind:class="{loading: showLoader}">
    <form v-bind:action="execute" v-bind:name="id" v-bind:id="formId" enctype="multipart/form-data" class="form-framework" v-bind:data-locale="locale" v-bind:data-sourceid="id" data-send-method="ajax">
      <div class="modal-body">
        <input type="hidden" name="resizeForFileId" :value="resizeForFileId">
        <div v-for="(value, key) in params" v-bind:key="value">
          <input type="hidden" v-bind:name="key" v-bind:value="value">
        </div>
      </div>
      <div v-if="labelsEnabled" class="modal-footer pb-0">
        <button type="submit" class="btn btn-primary large" v-html="labels.button.submit"></button>
      </div>
      <input type="hidden" name="form_action" :value="originId">
    </form>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXActionPopup from '@/components/mixins/actionPopup'
import ContentErrorMessage from '@/components/_content_error_message.vue'

export default mixins(MXActionPopup).extend({
  name: 'ActionResizeActivity',
  components: {
    ContentErrorMessage
  },
  props: {
    resizeForFileId: String
  },
  methods: {
    getRequestParams (): {[key: string]: any} {
      return {
        resizeForFileId: this.resizeForFileId
      }
    }
  }
})
</script>
