<template>
  <div class="form-group mark-container">
    <label v-bind:for="id" v-bind:class="{required:isRequired, 'show-label': showLabel, 'without-animation': !valueChanged, 'focus': focused }" class="input-text">
      <span v-html="label"></span>
      <input v-bind:type="fieldType"
          class="form-control"
          v-bind:class="{'invalid-backend': isInvalid}"
          v-bind:placeholder="isRequired ? label + '*' : label"
          v-bind:data-type="dataType"
          v-bind:data-validation-rules="validationRules"
          v-bind:id="id"
          v-bind:name="field.name"
          v-model="value"
          v-bind:required="isRequired"
          v-bind:readonly="isReadonly"
          v-bind:disabled="isDisabled"
          v-bind:data-error-message-selector="errorMessageSelector"
          v-bind:data-error-message="errorMessage"
          v-bind:data-error-type-message="errorMessageType"
          v-bind:maxlength="maxLength"
          v-bind:data-min="sizeMin"
          v-bind:data-max="sizeMax"
          v-bind:data-master-selector="masterSelector"
          v-bind:data-format="dateFormat"
          v-bind:data-datepicker-enabled="datepickerEnabled"
          v-bind:data-disable-manual-input="isDisableManualInput"
          v-bind:data-change-year="changeYear"
          v-bind:data-disabled-ranges="disabledRangesAsString"
          v-bind:data-start-date-field-name="startDateFieldName"
          v-bind:data-end-date-field-name="endDateFieldName"
          v-bind:data-digits-integer="digitsInteger"
          v-bind:data-digits-fraction="digitsFraction"
          v-bind:data-reg-exp="patternRegExp"
          v-bind:data-min-value="minValue"
          v-bind:data-max-value="maxValue"
          v-on:keyup="keymonitor"
          v-on:blur="keymonitor"
          v-on:click="keymonitor"
          v-bind:autocomplete="autocomplete"
          @input="onInputChange"
          @focus="setFocused(true)"
          @blur="setFocused(false)"
      ><i v-if="fieldType == 'password'" class="icon eye-slash" id="toggle-password"></i>
      <div v-if="fieldType == 'password' && passwordStrengthMeter" class="password-strength" v-bind:id="'password-strength-' + id"><div class="pointer"></div><div class="text"></div></div>
      <i v-if="datepickerEnabled" class="icon calendar input-icon"></i>
    </label>
    <div class="error-message" v-bind:id="errorMessageID"></div>
    <div class="error-message backend"><p v-for="message in errorMessages" v-bind:key="message">{{getMessageText(message)}}</p></div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import jQuery from 'jquery'
import { globalVar } from '@/scripts/own/_globalVar'
import { IFormField } from '@/types/app.d'
import { generalService } from '@/scripts/services/GeneralService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import { Password } from '@/scripts/own/password'

export default Vue.extend({
  name: 'f-input-text',
  data (): {[key: string]: any} {
    return {
      guid: '',
      value: null,
      valueChanged: false,
      focused: false
    }
  },
  props: {
    field: Object as PropType<IFormField>,
    type: String,
    disabled: String,
    errorMessageRequired: String,
    errorMessageInvalidInput: String,
    changeYear: String,
    disabledRanges: Array as PropType<any>,
    startDateFieldName: String,
    endDateFieldName: String,
    autocomplete: String,
    passwordStrengthMeter: Boolean,
    disableManualInput: Boolean,
    onChange: Function
  },
  updated (): void {
    this.$emit4AllParents('InputTextUpdated', {
      $props: this.$props,
      value: this.value,
      guid: this.guid
    })
  },
  mounted (): void {
    this.guid = generalService.createGuid()
    this.value = this.field.value
    if (this.datepickerEnabled) {
      setTimeout(() => {
        jQuery('#' + this.id).on('change', (e:any): void => {
          this.value = e.target.value
        })
      }, 0)
    }
    if (typeof this.onChange === 'function') {
      setTimeout(() => {
        jQuery('#' + this.id).on('change', (e:any): void => {
          this.onChange(e)
        })
      }, 0)
    }    
    setTimeout(() => {
      this.checkPassword()
    }, 100)
  },
  watch: {
    'field.value' (newVal: any): void {
      if (typeof newVal !== 'undefined') {
        if ((newVal === null) || (this.value === null) || (newVal.toString() !== this.value.toString())) {
          this.value = newVal
        }
      }
    }
  },
  computed: {
    id (): string {
      return  typeof this.field !== 'undefined' ? 'id_' + this.field.name.replace(/\./g, '_') + '_' + this.guid : 'id_undefined_' + this.guid
    },
    label (): string {
      return typeof this.field !== 'undefined' ? this.field.label : ''
    },
    fieldType (): string {
      return this.field.type ? (((this.field.type.toLowerCase() === 'email') || (this.field.type.toLowerCase() === 'date') || (this.field.type.toLowerCase() === 'decimal') || (this.field.type.toLowerCase() === 'int') || (this.field.type.toLowerCase() === 'long')) ? 'text' : this.field.type.toLowerCase()) : 'text'
    },
    dataType (): string {
      if (typeof this.type !== 'undefined') return this.type
      let dt = 'text'
      dt = this.field.type.toLowerCase() === 'date' ? 'date' : dt
      dt = this.field.type.toLowerCase() === 'email' ? 'email' : dt
      dt = this.field.type.toLowerCase() === 'password' ? 'text' : dt
      dt = this.field.type.toLowerCase() === 'url' ? 'text' : dt
      dt = this.field.type.toLowerCase() === 'int' ? 'int' : dt
      dt = this.field.type.toLowerCase() === 'long' ? 'long' : dt
      dt = this.field.type.toLowerCase() === 'decimal' ? 'decimal' : dt
      return dt
    },
    validationRules (): string {
      let r = ''
      const ValidationRuleList = ['Digits', 'Future', 'Max', 'Min', 'Past', 'Pattern', 'Size', 'Mirroring']
      for (let i = 0; i < ValidationRuleList.length; i++) {
        if (this.isValidationRuleEnabled(ValidationRuleList[i])) {
          r += (r === '' ? '' : ',') + ValidationRuleList[i].toLocaleLowerCase()
        }
      }
      return r
    },
    isRequired (): boolean {
      return typeof this.field === 'undefined' ? false : this.field.required
    },
    isReadonly (): boolean {
      return typeof this.field === 'undefined' ? true : this.field.readonly
    },
    isDisabled (): boolean {
      return this.disabled === 'true'
    },
    errorMessage (): string {
      return (this.errorMessageRequired) ? this.errorMessageRequired : (this.field.errorMessage === undefined ? 'required' : this.field.errorMessage)
    },
    errorMessageType (): string | boolean {
      return ((typeof this.errorMessageInvalidInput !== 'undefined') && (this.errorMessageInvalidInput !== '')) ? this.errorMessageInvalidInput : (this.getInvalidInputMessage() ? this.getInvalidInputMessage() : 'invalid input')
    },
    errorMessageID (): string {
      return 'error_' + this.id
    },
    errorMessageSelector (): string {
      return '#' + this.errorMessageID
    },
    maxLength (): string | boolean {
      if (!this.isValidationRuleEnabled('Size')) return false
      const maxOption = this.getValidationOptionByNameForRule('Size', 'max')
      if (maxOption) return maxOption
      return false
    },
    sizeMin (): string | boolean {
      return this.getValidationOptionByNameForRule('Size', 'min')
    },
    sizeMax (): string | boolean {
      return this.getValidationOptionByNameForRule('Size', 'max')
    },
    dateFormat (): null | string | boolean {
      return typeof this.field.format !== 'undefined' ? this.field.format : false
    },
    datepickerEnabled (): string | boolean {
      return this.dataType === 'date' ? 'true' : false
    },
    isDisableManualInput (): string | boolean {
      if (typeof this.disableManualInput !== 'undefined') {
        return this.disableManualInput ? 'true' : false
      } else {
        return this.dataType === 'date' ? 'true' : false
      }
    },
    errorMessages (): string | null {
      const r: any = []
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') r.push(this.field.messages[i].text)
        }
        return r
      } return null
    },
    isInvalid (): boolean {
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') return true
        }
      } return false
    },
    disabledRangesAsString (): string |boolean {
      let r: Array<{[key: string]: string}> | null = null
      if ((typeof this.disabledRanges !== 'undefined') && (this.disabledRanges !== null) && (Array.isArray(this.disabledRanges))) {
        r = []
        for (let i = 0; i < this.disabledRanges.length; i++) {
          r.push({
            from: typeof this.disabledRanges[i].from !== 'undefined' ? this.disabledRanges[i].from.value : null,
            to: typeof this.disabledRanges[i].to !== 'undefined' ?this.disabledRanges[i].to.value : null
          })
        }
      }
      return r ? JSON.stringify(r) : false
    },
    digitsInteger (): string | boolean {
      return this.getValidationOptionByNameForRule('Digits', 'integer')
    },
    digitsFraction (): string | boolean {
      return this.getValidationOptionByNameForRule('Digits', 'fraction')
    },
    patternRegExp (): string | boolean {
      return this.getValidationOptionByNameForRule('Pattern', 'regexp')
    },
    minValue (): string | boolean {
      return this.getValidationOptionByNameForRule('Min', 'value')
    },
    maxValue (): string | boolean {
      return this.getValidationOptionByNameForRule('Max', 'value')
    },
    masterSelector (): string | boolean {
      return this.getValidationOptionByNameForRule('Mirroring', 'masterSelector')
    },
    showLabel (): boolean {
      return !!this.value
    }
  },
  methods: {
    getMessageText (index: string): string {
      return typeof globalVar.message[index] !== 'undefined' ? globalVar.message[index] : index
    },
    isValidationRuleEnabled (name: string): boolean {
      return (typeof this.field.validation !== 'undefined') && (this.field.validation !== null) && (typeof this.field.validation[name] !== 'undefined')
    },
    getValidationOptionByNameForRule (ruleName: string, optionName: string): any | boolean {
      if (!this.isValidationRuleEnabled(ruleName)) return false
      if ((typeof this.field.validation !== 'undefined') && (this.field.validation !== null) && (typeof this.field.validation[ruleName] !== 'undefined') && (typeof this.field.validation[ruleName].options !== 'undefined') && (typeof this.field.validation[ruleName].options[optionName] !== 'undefined')) {
        return this.field.validation[ruleName].options[optionName]
      } else {
        return false
      }
    },
    getInvalidInputMessage (): string | boolean {
      if (typeof this.field.validation !== 'undefined') {
        const r = {}
        for (const name in this.field.validation) {
          if ((name !== 'NotNull') && (name !== 'NotBlank')) {
            r[name.toLowerCase()] = this.field.validation[name].message
          }
        }
        return JSON.stringify(r)
      } else {
        return false
      }
    },
    $emit4AllParents (eventName: string, data: any): void {
      vueTemplateService.$emit4AllParents(this, eventName, data)
    },
    keymonitor (e: any): void {
      this.checkPassword()
    },
    checkPassword (): void {
      if (this.passwordStrengthMeter) {
        const specialChars = '+-=Â¦|~!@#$%&*_'
        const pw = new Password(this.value, specialChars)
        const r = pw.getPwStrength()
        const verdict = r.verdict
        let percent = r.percent
        percent = percent === 0 && verdict === 'veryweak' ? 2 : percent
        jQuery('#password-strength-' + this.id).find('.text').html(globalVar.view.default.labels.passwordVerdict[verdict])
        jQuery('#password-strength-' + this.id).find('.pointer').css({ width: (100 - percent) + '%' })
      }
    },
    onInputChange (): void {
      this.valueChanged = true
    },
    setFocused (val: boolean): void {
      this.focused = val
    }
  }
})
</script>
