<template>
  <div class="datasource" v-bind:class="{loading: showLoader}">
    <div v-cloak v-bind:id="'datasource_'+id">
      <div class="scroller">
        <div class="top-panel">
            <div class="headline-panel text-center">
              <h2 v-html="labels.headline"></h2>
            </div>
        </div>
        <div class="dynamic-top-panel"></div>
        <div class="scroll-panel">
          <div class="scroller-vertical">
            <div class="pe-2 me--2">
              <div class="row mb-5" v-if="results.length == 0">
                <div class="col-sm-12">
                  <p><strong v-html="labels.noNotifications"></strong></p>
                </div>
              </div>
              <fragment v-for="row in results" v-bind:key="row.fields.type.value + '_' + row.fields.id.value">
                <div v-if="row.fields.id.value === resultsNew().id && row.fields.type.value === resultsNew().type" class="list-item-headline new" v-html="labels.subheadlines.new"></div>
                <div v-if="row.fields.id.value === resultsWeekly().id && row.fields.type.value === resultsWeekly().type" class="list-item-headline last7days" v-html="labels.subheadlines.last7days"></div>
                <div v-if="row.fields.id.value === resultsOlder().id  && row.fields.type.value === resultsOlder().type" class="list-item-headline older" v-html="labels.subheadlines.older"></div>
                <div class="list-item" v-if="row.fields.type.value !== 'DELETED'" v-bind:class="{'without-thumbnail': !isThumbnailEnabled(row)}" :data-type="row.fields.type.value">
                  <div class="wrapper" @mousedown.middle.prevent.stop="(e) => onClickCard(e, getFeedItemUrl(row), true)" @click="(e) => onClickCard(e, getFeedItemUrl(row))" :data-url="getFeedItemUrl(row)">
                    <profilePictureThumbnail v-if="row.fields.author.value.visible.value" :linking="true" :nickName="row.fields.author.value.nickName.value" :pictureUrl="row.fields.author.value['image.previewPath'].value"></profilePictureThumbnail>
                    <img v-else class="profile-picture thumbnail rounded" v-bind:src="getAvatarDummyPicture()" alt="Deleted User " title="Deleted User">
                    <div class="text" :class="{'with-button-panel': isButtonPanelEnabled(row)}">
                      <div class="mb-2" :class="{'pe-3': row.fields.type.value === 'USER_COMMENT' || row.fields.type.value === 'COMMENT_LIKE_USER' || row.fields.type.value === 'FOLLOWER'}" v-html="getText(row)"></div>
                      <textWithReadMore v-if="row.fields.message.value" class="message quote" :text="row.fields.message.value" :maxLength="128"></textWithReadMore>
                      <relativeDateTime :dateTimeUTC="row.fields.dateTimeUTC.value"></relativeDateTime>
                    </div>
                    <div class="thumbnail" v-if="isThumbnailEnabled(row)">
                      <router-link :to="getFeedItemUrl(row)"><img v-bind:src="getThumbnailPicture(row)" alt=""></router-link>
                    </div>
                    <div class="button-panel" v-if="isButtonPanelEnabled(row)" :class="(row.fields.type.value === 'FRIENDSHIP_REQUEST') ? 'friendship' : ''">
                      <followerControl v-if="row.fields.type.value === 'FOLLOWER'" v-bind:state="getFollowState(row)" v-bind:buttonsLayout="'primary'"></followerControl>
                      <friendshipControl v-if="row.fields.type.value === 'FRIENDSHIP_REQUEST'" v-bind:state="getFriendshipState(row)" :layout="getFriendshipLayout()"></friendshipControl>
                    </div>
                  </div>
                  <div class="button-remove-item">
                    <a v-if="row.fields.type.value !== 'FRIENDSHIP_REQUEST'" href="javascript: void(0)" class="icon close-small" @click="(e) => removeNotification(row)"></a>
                  </div>
                </div>
              </fragment>
              <div v-if="loadingInProgress && !dataCompleteLoaded" class="datasource loading compact"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXDatasourceGeneral from '@/components/mixins/datasourceGeneral'
import jQuery from 'jquery'
import { Bus as VuedalsBus } from '@/components/vuedals/main'
import { globalVar } from '@/scripts/own/_globalVar'
import { uiService } from '@/scripts/services/UIService'
import { dataService } from '@/scripts/services/DataService'
import { dateTimeService } from '@/scripts/services/DateTimeService'
import { generalService } from '@/scripts/services/GeneralService'
import { appComponentsStatesService } from '@/scripts/services/AppComponentsStatesService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import clip from "text-clipper"
import ContentErrorMessage from '@/components/_content_error_message.vue'
import profilePictureThumbnail from '@/components/_profile_picture_thumbnail.vue'
import relativeDateTime from '@/components/_relative-date-time.vue'
import textWithReadMore from '@/components/_text-with-read-more.vue'
import { IComponentDataInfinityScrolling } from '@/types/app.d'
import friendshipControl from '@/components/_friendship-control.vue'
import followerControl from '@/components/_follower-control.vue'

var currentTime: number
type notificationID = {
  id: number;
  type: string;
}

export default mixins(MXDatasourceGeneral).extend({
  name: 'DatasourceNotifications',
  components: {
    ContentErrorMessage,
    profilePictureThumbnail,
    relativeDateTime,
    textWithReadMore,
    friendshipControl,
    followerControl
  },
  data (): IComponentDataInfinityScrolling {
    return {
      id: '',
      guid: '',
      originId: '',
      title: '',
      filter: {},
      setFilters: [],
      field: {},
      links: [],
      pagination: null,
      params: {},
      permissions: {},
      results: [],
      sort: [],
      labels: {},
      layout: {},
      messages: null,
      images: null,
      currentUrl: '',
      isDataRequestError: null,
      pageSize: 10,
      loadingInProgress: false,
      dataCompleteLoaded: false
    }
  },
  mounted (): void {
    this.$on('afterChangeDatasource', (e: any): void => {
      generalService.log('Event updateDatasource', e)
    })
    const waitForOpenInViedals = (callback) => {
      if (jQuery('.vuedal .scroller-vertical').length === 0) {
        setTimeout(() => waitForOpenInViedals(callback), 100)
      } else {
        if (typeof callback === 'function') callback()
      }
    }
    waitForOpenInViedals(() => {
      jQuery('.vuedals > div').on('click', (e: any): void => {
        uiService.closeAllDropdownOnVuedalClick(e)
      })      
    })
  },
  updated (): void {
    jQuery('a[data-router-link="true"]:not([data-initialized="true"])').each((index: number, that: HTMLElement): void => {
      jQuery(that).attr('data-initialized', 'true').on('click', (e: any) => {
        if (!e.originalEvent.ctrlKey) {
          e.preventDefault()
          const href: any = jQuery(e.target).attr('href')
          if (href) this.$router.push(href)
        }
      })
    })
  },
  methods: {
    startFetchData (): void {
      this.fetchData(undefined, () => {
        this.$nextTick(() => {
          this.initVerticalScrolling()
          this.initInfinityScrolling()
        })
      })
    },
    resultsNew(): notificationID {
      const notificationID: notificationID = {
        id: -1,
        type: ''
      }
      const rows = this.results.filter(row => row.fields.isNew.value)
      if (rows.length !== 0){
        notificationID.id = rows[0].fields.id.value
        notificationID.type = rows[0].fields.type.value
      }
      return notificationID
    },
    resultsWeekly(): notificationID {
      const notificationID: notificationID = {
        id: -1,
        type: ''
      }
      let time7daysbefore = currentTime - dateTimeService.getMillisecondsForPeriod (7, 0, 0, 0) //days, hours, minutes, seconds
      // round timestamp to calendar day
      time7daysbefore = time7daysbefore - time7daysbefore % dateTimeService.getMillisecondsForPeriod (1, 0, 0, 0)
      const rows = this.results.filter(row => (row.fields.dateTimeUTC.value >= time7daysbefore && !row.fields.isNew.value))
      if (rows.length !== 0) {
        notificationID.id = rows[0].fields.id.value
        notificationID.type = rows[0].fields.type.value
      }
      return notificationID
    },
    resultsOlder(): notificationID {
      const notificationID: notificationID = {
        id: -1,
        type: ''
      }
      let time7daysbefore = currentTime - dateTimeService.getMillisecondsForPeriod (7, 0, 0, 0) //days, hours, minutes, seconds
      // round timestamp to calendar day
      time7daysbefore = time7daysbefore - time7daysbefore % dateTimeService.getMillisecondsForPeriod (1, 0, 0, 0)

      const rows = this.results.filter(row => (row.fields.dateTimeUTC.value < time7daysbefore && !row.fields.isNew.value))
      if (this.resultsNew().id === -1 && this.resultsWeekly().id === -1) return notificationID     
      else if (rows.length !== 0) {
        notificationID.id = rows[0].fields.id.value
        notificationID.type = rows[0].fields.type.value
      }
      return notificationID
    },
    fetchData (maxDate?: number, callback?: any): void {
      if (this.componentSourceURL) {
        currentTime = dateTimeService.getLocalDateTimeUTC()
        const params = {
          maxDate: typeof maxDate !== 'undefined' ? maxDate : dateTimeService.getLocalDateTimeUTC(), 
          days: 14
        }
        dataService.getContentData4Datasource(this, this.componentSourceURL, params, (data: any) => {
          if (typeof maxDate === 'undefined') { // first page request
            dataService.updateComponentData(this, data)
            this.restoreSavedState()
            if (this.results.length >= 1 && this.results.length < 15) this.loadNextPage()
          } else {
            if (data.results !== null && data.results.length > 0) {
              for (let i = 0; i < data.results.length; i++) {
                this.results.push(data.results[i])
              }
              this.pagination = data.pagination
              if (this.results.length >= 1 && this.results.length < 15) this.loadNextPage()
            } else { // all pages are loaded
              if (typeof callback === 'function') callback({status: 'DATASOURCE_COMPLETE_LOADED'})
              return
            }
          }
          if (typeof callback === 'function') callback()
        })
      }
    },
    refreshContent (): void {
      this.fetchData()
    },
    initInfinityScrolling (): void {
      jQuery('#datasource_' + this.id + ' .scroller-vertical').on(generalService.getScrollendEventName(), (scroller: any): void => {
        if ((scroller.target.scrollTop + scroller.target.clientHeight + scroller.target.clientHeight / 2) >= scroller.target.scrollHeight) {
          this.loadNextPage(scroller)
        }
      })
    },
    loadNextPage (scroller?: any): void {
      if (this.loadingInProgress || this.dataCompleteLoaded) return
      this.loadingInProgress = true

      this.$nextTick(() => {
        if (typeof scroller !== 'undefined') {
          jQuery(scroller.target).scrollTop(scroller.target.scrollHeight)
        }
      })

      const resLength = this.results.length
      if (resLength > 0) {
        this.fetchData((this.results[resLength - 1].fields.dateTimeUTC.value - 1000), (e?: {[key: string]: string}): void => {
          if (typeof e !== 'undefined' && e.status === 'DATASOURCE_COMPLETE_LOADED') {
            this.dataCompleteLoaded = true
          }
          this.loadingInProgress = false
        })
      }
    },    
    formatDateTimeOutput (dateTime: string): string {
      return dateTimeService.formatDateTimeOutput(dateTime)
    },
    getAvatarDummyPicture (): string{
      return globalVar.appEngine.images.avatarDummyPicture
    }, 
    getDateTimeTimezoneString(dateTimeUTC: number, timeZoneName: string) {
      return dateTimeService.getDateTimeTimezoneString(this, dateTimeUTC, timeZoneName)
    },
    getDistanceInfo (data: {[key: string]: any}): string {
      return uiService.getDistanceInfo(data)
    },
    getSpeedInfo (data: {[key: string]: any}): string {
      return uiService.getSpeedInfo(data)
    },
    getSpeedInfoTooltip (data: {[key: string]: any}): string {
      return uiService.getSpeedInfoTooltip(data)
    },
    saveViewState ():void {
      appComponentsStatesService.saveViewState('NOTIFICATIONS_VIEW', {
        pagination: {
          page: this.pagination ? this.pagination.number : 0,
          pageSize: this.pagination ? this.pagination.size : 0
        },
        scrollTop: jQuery(window).scrollTop()
      })
    },
    getSavedViewState (): any {
      return appComponentsStatesService.getSavedViewState('NOTIFICATIONS_VIEW')
    },
    restoreSavedState (): void {
      const state = this.getSavedViewState()
      if (state !== null) {
        setTimeout(() => {
          window.scrollTo(0, state.scrollTop)
        }, 100)
        appComponentsStatesService.deleteSavedViewState('NOTIFICATIONS_VIEW')
      }
    },
    isThumbnailEnabled (row: any): boolean {
      const typesWithoutThumbnail = ['USER_COMMENT', 'USER_COMMENT_REPLY', 'COMMENT_LIKE_USER', 'FRIENDSHIP_REQUEST', 'FRIENDSHIP_ACCEPT', 'FRIENDSHIP_CONFIRM', 'MENTION_USER', 'MENTION_COMMENT_USER', 'FOLLOWER']
      return !typesWithoutThumbnail.includes(row.fields.type.value)
    },
    isButtonPanelEnabled (row: any): boolean {
      const typesWithButtonPanel = ['FRIENDSHIP_REQUEST', 'FOLLOWER'] //['FRIENDSHIP_REQUEST', 'FRIENDSHIP_ACCEPT', 'FRIENDSHIP_CONFIRM']
      return typesWithButtonPanel.includes(row.fields.type.value)
    },
    getText (row: any): string {
      const typesWithSingleForm = ['ASSET_LIKE', 'ACTIVITY_LIKE', 'COMMENT_LIKE_USER', 'COMMENT_LIKE_ACTIVITY', 'COMMENT_LIKE_ASSET']
      const typesWithoutOtherOwner = ['FOLLOWER', 'FRIENDSHIP_ACCEPT', 'FRIENDSHIP_CONFIRM', 'FRIENDSHIP_REQUEST', 'MENTION_USER', 'MENTION_ACTIVITY', 'MENTION_ASSET', 'MENTION_COLLECTION', 'MENTION_POINT']
      const isSingleForm = row.fields.count.value === 0 && typesWithSingleForm.includes(row.fields.type.value)
      const isReply = row.fields.replyToId?.value !== ''
      const author = row.fields.author?.value?.nickName.value
      const authorVisible = row.fields.author?.value?.visible.value
      const owner = row.fields.owner?.value?.nickName.value
      const isSameOwner =  owner === this.$store.state.user?.nickName
      const isOtherOwner = !typesWithoutOtherOwner.includes(row.fields.type.value) && !isSameOwner
      let messageKey= 'feed.' + row.fields.type.value + (isSingleForm ? '.single' : '') + (isReply ? '.reply' : '') + (isOtherOwner ? '.otherOwner' : '')
      
      const placeholders = {
        authorNickName: (authorVisible) ? '<b>@' + author + '</b>' : '<span style="opacity:0.8;">@' + author + '</span>',
        activityTitle: '<b>' + row.fields.activityName.value + '</b>',
        ownerNickName: '<b>' + owner + '</b>',
        asset: row.fields.assetType.value ? (row.fields.assetType.value === 'IMAGE' ? uiService.getGlobalLabel('notifications', 'image') : uiService.getGlobalLabel('notifications', 'video') ) : '',
        yourAsset: row.fields.assetType.value ? (row.fields.assetType.value === 'IMAGE' ? uiService.getGlobalLabel('notifications', 'yourImage') : uiService.getGlobalLabel('notifications', 'yourVideo')) : '',
        toYourAsset: row.fields.assetType.value ? (row.fields.assetType.value === 'IMAGE' ? uiService.getGlobalLabel('notifications', 'toYourImage') : uiService.getGlobalLabel('notifications', 'toYourVideo')) : '',
        toAsset: row.fields.assetType.value ? (row.fields.assetType.value === 'IMAGE' ? uiService.getGlobalLabel('notifications', 'toImage') : uiService.getGlobalLabel('notifications', 'toVideo')) : '',
      }

      switch (row.fields.type.value) {
        case 'ASSET_LIKE':
        case 'ACTIVITY_LIKE':
        case 'COMMENT_LIKE_ASSET':
        case 'COMMENT_LIKE_ACTIVITY': {
          placeholders['numberOfOtherLikes'] = row.fields.count.value
          break
        }
        case 'USER_COMMENT': {
          placeholders['userCommentUrl'] = '/' + this.$store.state.user?.nickName + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'USER_COMMENT_REPLY': {
          placeholders['ownerNickName'] = '<b>' + owner + '</b>'
          placeholders['userCommentUrl'] = '/' + this.$store.state.user?.nickName + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }        
        case 'COMMENT_LIKE_USER': {
          placeholders['nickName'] = '<b>' + owner + '</b>'
          placeholders['numberOfOtherLikes'] = row.fields.count.value
          break
        }
        case 'FRIENDSHIP_REQUEST': {
          placeholders['requestMessage'] = '' //row.fields.message.value ? '<div class="quote">' + this.getMessage(row.fields.message.value) + '</div>' : ''
          break
        }
        case 'MENTION_USER': {
          placeholders['userCommentUrl'] = '/' + owner
          break
        }
        case 'MENTION_COMMENT_USER': {
          messageKey += isSameOwner ? '.sameOwner' : ''
          placeholders['userCommentUrl'] = '/' + owner + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '')
          placeholders['profile-page-owner'] = '<b>' + owner + '</b>'
          break
        }
      }
      let content = uiService.getGlobalMessageText(messageKey)
      content = jQuery.string.replacePlaceHolders(content, placeholders)
      return content
    },
    getFeedItemUrl (row: any): string {
      let url = ''
      const activityId = row.fields.activityId?.value;
      const owner = row.fields.owner?.value?.nickName.value
      const urlTitle = row.fields.urlTitle?.value
      switch (row.fields.type.value) {
        case 'ACTIVITY_COMMENT':
        case 'ACTIVITY_COMMENT_REPLY': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=commentsForActivity;id='+ row.fields.commentId.value +';activityId=' + activityId + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'ASSET_COMMENT':
        case 'ASSET_COMMENT_REPLY': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=commentsForAsset;id=' + row.fields.commentId.value +';activityId=' + activityId + ';itemId=' + row.fields.assetId.value + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'ASSET_LIKE': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=pointAsset;id=' + row.fields.assetId.value +';activityId=' + activityId
          break
        }
        case 'ACTIVITY_LIKE': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId
          break
        }
        case 'COMMENT_LIKE_ACTIVITY': {
          url = '/'+ owner +'/activities#referenceId=commentsForActivity;id='+ row.fields.commentId.value +';activityId=' + activityId + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'COMMENT_LIKE_ASSET': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=commentsForAsset;id=' + row.fields.commentId.value +';activityId=' + activityId + ';itemId=' + row.fields.assetId.value + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'MENTION_ASSET': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=pointAsset;id=' + row.fields.assetId.value +';activityId=' + activityId
          break
        }
        case 'MENTION_ACTIVITY': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId
          break
        }
        case 'MENTION_COLLECTION': {
          url = '/'+ owner + '/collection/' + row.fields.collectionId.value
          break
        }
        case 'MENTION_COMMENT_ACTIVITY': {
          url = '/'+ owner +'#referenceId=commentsForActivity;id='+ row.fields.commentId.value +';activityId=' + activityId + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'MENTION_COMMENT_ASSET': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=commentsForAsset;id=' + row.fields.commentId.value +';activityId=' + activityId + ';itemId=' + row.fields.assetId.value + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'FRIENDSHIP_REQUEST':
        case 'FRIENDSHIP_CONFIRM':
        case 'FRIENDSHIP_ACCEPT':
        case 'FOLLOWER': {
          url = '/'+ row.fields.author.value.nickName.value + '#feed'
        }
      }      
      return url
    },
    getMessage (text: string): string {
      return clip(text, 256, { html: true, maxLines: 3 })
    },
    getThumbnailPicture (row: any): string {
      return row.fields.thumbnailPath.value ? row.fields.thumbnailPath.value : this.dummyPictureUrl
    },
    getFriendshipLayout (): any {
      return {
        value: {
          icon: true,
          cancelFriendshipButton: true
        }
      }
    },
    getFriendshipState (row: any): any {
      return {
        value: {
          state: {
            value: row.fields.type.value === 'FRIENDSHIP_REQUEST' ? 'PENDING' : '' //'ACCEPTED' 'ABSENT' 'PENDING' 'WAITING' 'CANCELLED'
          },
          nickName: {
            value: row.fields.author.value.nickName.value
          },
          message: {
            value: ''
          },
          id: {
            value: row.fields.id.value + '_' + row.fields.type.value
          }
        } 
      }    
    },
    getFollowState (row: any): any {
      return {
        state: row.fields.author.value.followed.value ? 'DISABLED' : (row.fields.author.value.following.value ? 'BACKSENT' : 'DISABLED'),
        //'ACCEPTED' || 'DISABLED' || 'PRESENT' || 'BACKSENT' || 'ABSENT'
        nickName: row.fields.author.value.nickName.value,
        id: row.fields.id.value + '_' + row.fields.type.value
      }
    },
    callbackAfterChangeDatasource (e: any): void {
      const data = e.data
      if (e.data.originId === 'follow' || e.data.originId === 'unfollow' ||  e.data.originId === 'acceptFriendship' || e.data.originId === 'cancelFriendship') {
        if (!generalService.haveFieldsErrors(data.field) && generalService.wasRequestSuccessfully(data)) {
          jQuery('#alert-message-panel *').remove()
          const messages = uiService.getOkMessage(data)
          let responseMessage = ''
          for (let i = 0; i < messages.length; i++) {
            responseMessage += (i > 0 ? '<br/>' : '') + jQuery.string.replacePlaceHolders(messages[i], {
              nickName: e.data.originId === 'acceptFriendship' || e.data.originId === 'cancelFriendship' ? this.$store.state.user?.nickName : data.params.nickName,
              initiatorNickName: e.data.originId === 'acceptFriendship' || e.data.originId === 'cancelFriendship' ? data.params.nickName : data.userdata.nickName
            })
          }
          if (responseMessage !== '') uiService.showAlertMessage(responseMessage, 'success')
        }      
        vueTemplateService.$emit4AllParents(this, 'refreshFollowerControlInChildren', e)
        vueTemplateService.$emit4Children(this, 'refreshStats', e)
      }
      //refresh data in results array
      this.results.forEach((row: any): void => {
        if (data.params.id === row.fields.id.value + '_' + row.fields.type.value) {
          if (row.fields.type.value === 'FRIENDSHIP_REQUEST') {
            if (e.data.originId === 'acceptFriendship') {
              row.fields.type.value = 'FRIENDSHIP_CONFIRM'
              row.fields.dateTimeUTC.value = dateTimeService.getLocalDateTimeUTC()
            }
            if (e.data.originId === 'cancelFriendship') {
              row.fields.type.value = 'DELETED'
              this.$nextTick(() => {
                this.updateSubheadlineVisibility()
              })
            }
          }
          if (row.fields.type.value === 'FOLLOWER') {
            if (e.data.originId === 'follow') {
              row.fields.author.value.followed.value = true
            }
          }
        }
      })
    },
    removeNotification (row: any): void {
      const sourceURL = dataService.getServiceUrl(this, 'acknowledge')
      if (sourceURL) {
        dataService.getContentData4Action(this, sourceURL, {}, (data: any) => {
          if (data.messages.length === 0) {
            const requestData = new FormData()
            requestData.append('form_action', data.originId)
            requestData.append('id', row.fields.id.value.toString())
            requestData.append('type', row.fields.type.value)
            dataService.postData4Action(this, data.execute, requestData, (postResponseData: any) => {
              //nothing 
            }, true)
            row.fields.type.value = 'DELETED'
            this.$nextTick(() => {
              this.updateSubheadlineVisibility()
            })
          }
        })
      }
    },
    updateSubheadlineVisibility (): void {
      jQuery('.list-item-headline:visible').each((index: number, e: HTMLElement): any  => {
        if (jQuery(e).nextUntil('.list-item-headline:visible', '.list-item').length === 0) jQuery(e).addClass('hidden').hide()
      })
      if (jQuery('.list-item-headline:visible').length === 1 && jQuery('.list-item-headline:visible').hasClass('older')) jQuery('.list-item-headline:visible').addClass('hidden').hide()
    },
    trimDescription (text: string): string {
      return generalService.trimHTMLText(text)
    },
    isShortDescription (text: string): boolean {      
      return this.trimDescription(text).length < 128
    },
    getShortDescription (text: string): string {
      //clip(text, 128, { html: true, stripTags: ["br"], maxLines: 3 })
      return clip(this.trimDescription(text), 128, { html: true, maxLines: 3 })
    },
    showMessage (e: any): void {
      jQuery(e.target).parents('.message').find('.short-message').addClass('hidden')
      jQuery(e.target).parents('.message').find('.whole-message').removeClass('hidden')
    },
    hideMessage (e: any): void {
      jQuery(e.target).parents('.message').find('.whole-message').addClass('hidden')
      jQuery(e.target).parents('.message').find('.short-message').removeClass('hidden')
    },
    initVerticalScrolling (): void {
      uiService.initVerticalScrollingInVuedal()
    },
    onClickCard (e: any, to: {[key: string]: any} | string, clickMiddle?: boolean): void {
      uiService.onClickCard(this, e, to, clickMiddle)
    }
  }
})
</script>
