<template>
  <div class="profile-picture thumbnail rounded">
    <router-link v-if="linking" :to="{name: 'publicProfile', params: {nickName: nickName}}">
      <img v-if="pictureUrl" :src="pictureUrl" :alt="nickName">
      <span v-else><strong>{{ nickName.substring(0,1).toUpperCase() }}</strong></span>
    </router-link>
    <fragment v-else >
      <img v-if="pictureUrl" :src="pictureUrl" :alt="nickName">
      <span v-else><strong>{{ nickName.substring(0,1).toUpperCase() }}</strong></span>
    </fragment>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'profilePictureThumbnail',
  props: {
    linking: Boolean,
    nickName: String,
    pictureUrl: String
  }
})
</script>
