<template>
  <fragment>
    <fragment v-if="isAuthenticated">
      <a v-if="followState == 'PRESENT'" href="javascript:void(0)" v-bind:class="'btn btn-'+getButtonsLayout+' follow-present'" v-bind:title="labels.tooltip.present + ' ' + state.nickName" @click.stop="unfollowUser()"><span class="on-default" v-html="labels.button.following"></span><span class="on-hover" v-html="labels.button.unfollow"></span></a>
      <a v-if="followState == 'ABSENT'" href="javascript:void(0)" v-bind:class="'btn btn-'+getButtonsLayout+' follow-absent'" v-bind:title="labels.tooltip.absent + ' ' + state.nickName" @click.stop="followUser()"><span v-html="labels.button.follow"></span></a>
      <a v-if="followState == 'BACKSENT'" href="javascript:void(0)" v-bind:class="'btn btn-'+getButtonsLayout+' follow-absent'" v-bind:title="labels.tooltip.backsent + ' ' + state.nickName" @click.stop="followUser()"><span v-html="labels.button.followback"></span></a>
    </fragment>
    <fragment v-else>
      <a href="javascript:void(0)" class="btn btn-secondary follow-absent" v-bind:title="labels.tooltip.absent" @click="loginAndRequestFollow()"><span v-html="labels.button.follow"></span></a>
    </fragment>
  </fragment>
</template>

<script lang="ts">
import Vue from 'vue'
import '@/scripts/own/jquery.string'
import { globalVar } from '@/scripts/own/_globalVar'
import { uiService } from '@/scripts/services/UIService'
import { dataService } from '@/scripts/services/DataService'
import { generalService } from '@/scripts/services/GeneralService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import { string } from 'jquery'

export default Vue.extend({
  name: 'follower-control',
  props: {
    state: Object,
    buttonsLayout: String
  },
  mounted (): void {
    this.$nextTick((): void => {
      if (sessionStorage.getItem('requestFollow') === 'true') {
        sessionStorage.removeItem('requestFollow')
        switch (this.followState) {
          case "ABSENT": {
            this.followUser()
            break
          }
        }
      }
      this.$on('afterChangeDatasource', (e: any): void => {
        if (e.successfully && e.successfully && e.data.originId === 'unfollow') {
          vueTemplateService.$emit4AllParents(this, 'afterChangeDatasource', e)
        }
      })
    })
  },
  computed: {
    followState (): string {
      return this.state && typeof this.state.state !== 'undefined' ? this.state.state : ''
    },
    getButtonsLayout (): string {
      return this.buttonsLayout && typeof this.buttonsLayout !== 'undefined' ? this.buttonsLayout : 'secondary'
    },
    isAuthenticated (): boolean {
      return this.$store.state.user.authenticated
    },
    labels (): {[key: string]: any} {
      return globalVar.view.default.labels.followerControl
    }
  },
  methods: {
    getServiceUrl (sourceId: string): string {
      return dataService.getServiceUrl(this, sourceId)
    },
    followUser (): void {
      const sourceURL = this.getServiceUrl('follow')
      if (sourceURL) {
        const params = {
          nickName: this.state.nickName
        }
        dataService.getContentData4Action(this, sourceURL, params, (data: any) => {
          if (data.messages.length === 0) {
            const requestData = new FormData()
            requestData.append('form_action', data.originId)
            requestData.append('nickName', params.nickName)
            dataService.postData4Action(this, data.execute, requestData, (postResponseData: any) => {
              generalService.log('> Follow: postResponseData', postResponseData)
              const successfully = generalService.wasRequestSuccessfully(postResponseData)
              if (typeof this.state.id !== 'undefined') {
                postResponseData.params['id'] = this.state.id
              }
              vueTemplateService.$emit4AllParents(this, 'afterChangeDatasource', { successfully: successfully, data: postResponseData })
            }, true)
          }
        })
      }
    },
    unfollowUser (): void {
      const sourceURL = this.getServiceUrl('unfollow')
      if (sourceURL) {
        const dialogContent = {
          title: (typeof globalVar.view['voyoa_unfollow_followerEvent'] !== 'undefined') && (typeof globalVar.view['voyoa_unfollow_followerEvent'].labels !== 'undefined') ? globalVar.view['voyoa_unfollow_followerEvent'].labels.headline : 'Unfollow User',
          body: (typeof globalVar.view['voyoa_unfollow_followerEvent'] !== 'undefined') && (typeof globalVar.view['voyoa_unfollow_followerEvent'].labels !== 'undefined') ? globalVar.view['voyoa_unfollow_followerEvent'].labels.text : 'Are you sure?'
        }
        uiService.showViewInPopup('ActionPopup', sourceURL, {nickName: this.state.nickName, executeDirectly: false}, dialogContent, this, 'sm')
      }
    },
    loginAndRequestFollow (): void {
      sessionStorage.setItem('redirectUrl', this.$router.currentRoute.fullPath)
      sessionStorage.setItem('requestFollow', 'true')
      this.$router.push({name: 'login', params: {}})
    }
  }
})
</script>
