<template>
  <div class="content">
    <h2 class="get-app-header">Voyoa App</h2>
    <div class="row">
      <div :class="getClassForIOs()">
        <a href="https://apps.apple.com/de/app/voyoa/id6446172593" title="Download Voyoa on the App Store"><img class="d-inline" src="/resources/app-store-badge-128x128.png" alt="Download Voyoa on the App Store"></a>
      </div>
      <div :class="getClassForAndroid()">
        <a href="https://play.google.com/store/apps/details?id=com.voyoa" title="Get Voyoa on Google Play"><img class="d-inline" src="/resources/google-play-badge-128x128.png" alt="Get Voyoa on Google Play"></a>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import Vue from 'vue'
import { uiService } from '@/scripts/services/UIService'
import { isMobile } from '@/scripts/own/ait-common'

export default Vue.extend({
  name: 'AddItem',
  props: {
    nickName: String
  },
  methods: {
    getButtonLabel (name: string): string {
      return uiService.getGlobalLabel('button', name)
    },
    isMobileDevice (): boolean {
      return isMobile.any() !== null
    },
    isIOs (): boolean {
      return isMobile.iOS() !== null
    },
    isAndroid (): boolean {
      return isMobile.Android() !== null
    },
    getClassForIOs (): string {
      return this.isIOs() ? 'col-12 text-center' : (this.isAndroid() ? 'hidden' : 'col-6 text-end')
    },
    getClassForAndroid (): string {
      return this.isAndroid() ? 'col-12 text-center' : (this.isIOs() ? 'hidden' : 'col-6 text-start')
    }
  }
})
</script>

<style>
  .get-app-header {
    text-align: center;
    margin-bottom: -1rem;
    padding-top: 1rem;
  }
</style>