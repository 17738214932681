<template>
  <div class="action mt-4 mb-4" v-bind:class="{loading: showLoader}">
    <div class="logo icon voyoa-logo-beta"><router-link v-bind:to="{ name: 'root'}"><img :src="getLogoImage()"></router-link></div>
    <h1 v-if="headlineEnabled" class="text-center" v-html="headline"></h1>
    <div class="alert alert-info text-center" v-if="hasSessionExpired" v-html="getMessageSessionHasExpired()"></div>
    <div class="alert alert-info text-center" v-if="isAuthenticationNeeded" v-html="getMessageAuthenticationNeeded()"></div>
    <form v-if="fieldsEnabled" v-bind:action="execute" v-bind:name="id" v-bind:id="formId" enctype="multipart/form-data" class="form-framework" v-bind:data-locale="locale" v-bind:data-sourceid="id" data-send-method="ajax">
      <fieldset>
        <div class="row">
          <f-field v-bind:field="field.username" v-bind:error-message-required="labels.message.usernameRequired" v-bind:cell-size="'12'"></f-field>
          <f-field v-bind:field="field.password" v-bind:error-message-required="labels.message.passwordRrequired" v-bind:cell-size="'12'"></f-field>
        </div>
      </fieldset>
      <fieldset>
        <div class="row">
          <div class="form-block col-sm">
            <input type="hidden" value="" name="ghost">
            <button type="submit" class="btn btn-primary large large w-100" v-html="getButtonLabel('login')"></button>
          </div>
        </div>							
      </fieldset>
      <div v-for="(value, key) in params" v-bind:key="key+'_'+value">
        <input type="hidden" v-bind:name="key" v-bind:value="value">
      </div>
      <input type="hidden" name="form_action" :value="originId">
    </form>
    <div class="mt-5 text-center" v-if="labelsEnabled">
      <div class="row">
        <div class="col"><router-link v-bind:to="{name: 'passwordForget'}" role="button" v-html="getButtonLabel('passwordForget')"></router-link></div>
      </div>
      <!-- div class="row">
        <div class="col"><router-link v-bind:to="{name: 'registration'}" role="button">{{labels.button.registration}}</router-link></div>
      </div -->
      <div class="row">
        <div class="col"><router-link v-bind:to="{name: 'digestRegistration'}" role="button" v-html="getButtonLabel('digestRegistration')"></router-link></div>
      </div>
      <!-- div class="row">
        <div class="col"><router-link v-bind:to="{name: 'confirm', params: {digest: 'ttaJXNYgVbKSzmiicQDWUGCOPndgUSCP'}" role="button">Confirm registration</router-link></div>
      </div>
      <div class="row">
        <div class="col"><router-link v-bind:to="{name: 'ssoLogin', params: {username: 'JamesCook', ts: '1572359715', sig: 'e5332470ca72e21a7ea14433985ab153'}}" role="button">SSO login</router-link></div>
      </div -->
    </div>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXActionGeneral from '@/components/mixins/actionGeneral'
import { generalService } from '@/scripts/services/GeneralService'
import { dataService } from '@/scripts/services/DataService'
import { dateTimeService } from '@/scripts/services/DateTimeService'
import fField from '@/components/form/f-field.vue'
import { globalVar } from '@/scripts/own/_globalVar'
import ContentErrorMessage from '@/components/_content_error_message.vue'

export default mixins(MXActionGeneral).extend({
  name: 'ActionLogin',
  components: {
    fField,
    ContentErrorMessage
  },
  computed: {
    isAuthenticated (): boolean {
      return this.$store.state.user.authenticated
    },
    hasSessionExpired (): boolean {
      // console.info(sessionStorage)
      if (sessionStorage) {
        // console.info(sessionStorage.getItem('HTTP-401') === 'true', typeof this.messages?.length, this.messages?.length)
        if (typeof this.messages?.length === 'number' && this.messages?.length === 0) {
          return false
        } else {
          return sessionStorage.getItem('HTTP-401') === 'true'
        }
      } else {
        return false
      }
    },
    isAuthenticationNeeded (): boolean {
      return sessionStorage.getItem('redirectUrl') !== null && sessionStorage.getItem('redirectUrl') !== '' && sessionStorage.getItem('HTTP-471') === 'true'
    }
  },
  methods: {
    fetchData (): void {
      if (this.componentSourceURL) {
        dataService.getContentData4Action(this, this.componentSourceURL, {}, (data: any) => {
          dataService.updateComponentData(this, data)
          if (data.userdata.authenticated) {
            data['user'] = data.userdata
            this.$emit4AllParents('afterSendActionData', data)
          }
        })
      }
    },
    callbackAfterSendData (data: any): void {
      generalService.log('> afterSendData callback', this.$parent)
      if (sessionStorage) {
        sessionStorage.removeItem('HTTP-401')
        sessionStorage.setItem('sessionStartTime', dateTimeService.getLocalDateTimeUTC().toString())
      }
      this.messages = data.messages

      if (!generalService.haveFieldsErrors(data.field) && generalService.wasRequestSuccessfully(data)) {
        this.$emit4AllParents('afterSendActionData', data)
      }
    },
    getMessageSessionHasExpired (): string {
      return globalVar.message['error.noUserInSession']
    },
    getMessageAuthenticationNeeded (): string {
      return globalVar.message['error.authenticationNeeded']
    },
    getLogoImage (): string {
      return globalVar.appEngine.images.logoBeta
    }
  }
})
</script>
