import Vue from 'vue'
import { globalVar } from '../own/_globalVar'
import { isIFrame, guid } from '../own/ait-common'
import { dataService } from './DataService'
import { uiService } from '@/scripts/services/UIService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'

class GeneralService {
  public getLocale ():string {
    const browserLang = window.navigator.language
    if (typeof browserLang === 'string' && ['de'].includes(browserLang.split('-')[0])) return browserLang
    else return 'en-US'
    //return 'en-US'
  }
  public createGuid (): string {
    return guid()
  }
  public log (...args): void {
    if (globalVar.mode === 'dev') {
      switch (args.length) {
        case 1: {
          console.log(args[0])
          break
        }
        case 2: {
          console.log(args[0], args[1])
          break
        }
        case 3: {
          console.log(args[0], args[1], args[2])
          break
        }
        case 4: {
          console.log(args[0], args[1], args[2], args[3])
          break
        }
        case 5: {
          console.log(args[0], args[1], args[2], args[3], args[4])
          break
        }
        default:
          console.log(args)
          break
      }
    }
  }
  public isIFrame (): boolean {
    return isIFrame()
  }
  public hasErrorMessage (e: any): boolean {
    // fields error messages
    for (const name in e.field) {
      const field = e.field[name]
      if (field.messages !== null) {
        for (let i = 0; i < field.messages.length; i++) {
          if (field.messages[i].level === 'ERROR') return true
        }
      }
    }
    // global error messages
    if (!e.messages) return false
    for (let i = 0; i < e.messages.length; i++) {
      if (e.messages[i].level === 'ERROR') return true
    }
    return false
  }
  public moveArrayItem (arr: Array<any>, itemIndex: number, newIndex: number) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(newIndex, 0, arr.splice(itemIndex, 1)[0])
    return arr
  }
  public setPageMetaData (vm: any, metaData: {[key: string]: any}): void {
    if (typeof vm.metaData !== 'undefined') {
      for (const prop in vm.metaData) {
        if (typeof metaData[prop] !== 'undefined' && metaData[prop] !== null) {
          vm.metaData[prop] = metaData[prop]
        }
      }
    }
  }
  public wasRequestSuccessfully (data: {[key: string]: any}): boolean {
    let successfully = true
    if (Array.isArray(data.messages)) {
      for (let i = 0; i < data.messages.length; i++) {
        if ((data.messages[i].clazz === 'ERROR') || (data.messages[i].level === 'ERROR')) successfully = false
      }
    }
    return successfully
  }
  public haveFieldsErrors (fields: {[key: string]: any} | Array<{[key: string]: any}>): boolean {
    function checkFieldMessages (messages: Array<{[key: string]: string}>): boolean {
      let error = false
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].level === 'ERROR') {
          error = true
          break
        }
      }
      return error
    }
    let hasError = false

    if (Array.isArray(fields)) {
      for (let i = 0; i < fields.length; i++) {
        if ((typeof fields[i].type !== 'undefined') && (typeof fields[i].messages !== 'undefined')) {
          hasError = checkFieldMessages(fields[i].messages)
        } else { //Field is OBJECT or LIST_OBJECT
          hasError = this.haveFieldsErrors(fields[i])
        }
        if (hasError) break
      }
    } else {
      for (const field in fields) {
        if ((typeof fields[field].type !== 'undefined') && (typeof fields[field].messages !== 'undefined')) {
          hasError = checkFieldMessages(fields[field].messages)
        } else { //Field is OBJECT or LIST_OBJECT
          hasError = this.haveFieldsErrors(fields[field])
        }
        if (hasError) break
      }
    }
    return hasError
  }
  public copyTextToClipboard (text: string): void {
    if (navigator.clipboard) {
      if (navigator.userAgent.indexOf("Windows") === -1) {
        text = text.replace(/(?:\r\n|\r|\n)/g, '\n')
      }
      navigator.clipboard.writeText(text)
    }
  }
  public convertDecimalToDegreesMinutesSeconds (coordinate: number): string {
    const absolute = Math.abs(coordinate)
    const degrees = Math.floor(absolute)
    const minutesNotTruncated = (absolute - degrees) * 60
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60)
    return degrees + "°" + minutes + "'"  + seconds + '"'
  }
  public convertLatDMS (lat: number): string {
    const latitude = this.convertDecimalToDegreesMinutesSeconds(lat)
    const latitudeCardinal = lat >= 0 ? "N" : "S"
    return latitude + latitudeCardinal
  }
  public convertLngDMS (lng: number): string {
    const longitude = this.convertDecimalToDegreesMinutesSeconds(lng)
    const longitudeCardinal = lng >= 0 ? "E" : "W"
    return longitude + longitudeCardinal
  }
  public convertDMS (lat: number, lng: number): string {
    return this.convertLatDMS(lat) + " " + this.convertLngDMS(lng)
  }
  public loadLeafletScript (callback: (L: any) => void): void {
    const getLeafletScript = () => import('leaflet')
    const getLeafletPolylinedecorator = () => import('leaflet-polylinedecorator')
    const getLeafletMarkerCluster = () => import('leaflet.markercluster')
    getLeafletScript().then((leaflet: any): void => {
      getLeafletPolylinedecorator().then((e: any) => {
        getLeafletMarkerCluster().then((e: any) => {
          const getLeafletBigImage =  () => import('../own/leaflet.MapToImage')
          getLeafletBigImage().then((e: any) => {
            if (typeof window.L.Marker !== 'undefined' && typeof window.L.Marker.prototype.__setPos === 'undefined') {
              window.L.Marker.prototype.__setPos = window.L.Marker.prototype._setPos
              window.L.Marker.prototype._setPos = function (...args) {
                window.L.Marker.prototype.__setPos.apply(this, args)
                this._zIndex = this.options.zIndexOffset
                this._resetZIndex()
              }
            }
            if (typeof callback === 'function') callback(window.L)
          })
        })
      })
    })
  }
  public getHashParams (): null | {[key: string]: string} {
    let hash = window.location.hash
    let hashParams: null | {[key: string]: string} = null
    if (hash.length > 0) {
      hash = hash.substring(1)
      const hashParts = hash.split(';')
      if (hashParts.length > 0) hashParams = {}
      for (let i = 0; i < hashParts.length; i++) {
        const paramParts = hashParts[i].split('=')
        if (hashParams !== null) hashParams[paramParts[0]] = paramParts[1] ? paramParts[1] : ''
      }
    }
    return hashParams
  }
  public getHashString (hashParams: {[key: string]: string}): string {
    let r = '#'
    for (const key in hashParams) {
      r = r + key + '=' + hashParams[key] + ';'
    }
    return r.substring(0, r.length - 1)
  }
  public addParamsToBrowserHash (params: {[key: string]: any}): void {
    let hashParams = this.getHashParams()
    if (hashParams === null) hashParams = {}
    for (const paramName in params) {
      hashParams[paramName] = params[paramName].toString()
    }
    history.replaceState(undefined, '', generalService.getHashString(hashParams))
  }
  public loadNextDatasourcePage (vm: any, callback?: () => void): void {
    if (typeof vm.loadingInProgress !== 'undefined' && typeof vm.pageSize !== 'undefined') { //infinity scrolling
      if (!vm.loadingInProgress && vm.pagination !== null && vm.pagination.totalPages > vm.pagination.number + 1) {
        vm.loadingInProgress = true
        const sortParamName = 'sort' + vm.originId.substring(0, 1).toUpperCase() + vm.originId.substring(1)
        const params = {
          nickName: vm.nickName, 
        }
        params[sortParamName] = 'pageSize:'+ vm.pageSize.toString() +';page:' + (vm.pagination.number + 1).toString()
        if (typeof vm.query !== 'undefined' && vm.query.trim() !== '')  params['query'] = vm.query
        dataService.getContentData4Datasource(vm, vm.componentSourceURL, params, (data: any) => {
          for (let i = 0; i < data.results.length; i++) {
            vm.results.push(data.results[i])
          }
          vm.pagination = data.pagination
          vm.loadingInProgress = false
          if (typeof callback === 'function') callback()
        })
      }
    } else {
      if (typeof vm.loadNextPage === 'function') vm.loadNextPage(callback)
    }
  }
  public waitForContentRenderingEnd (selector: string, callback: () => void, counter?: number): void {
    counter = typeof counter === 'undefined' ? 0 : counter
    counter++
    if (counter < 40) {
      if (jQuery(selector).length === 0) {
        setTimeout(() => this.waitForContentRenderingEnd(selector, callback, counter), 200)
      } else {
        if (typeof callback === 'function') callback()
      }
    }
  }
  public searchForContentItem (vm: any, id: any, callback: () => void): void {
    if (typeof vm.results.find((item) => item.fields.id.value ===  parseInt(id)) !== 'undefined') {
      callback()
    } else {
      this.loadNextDatasourcePage(vm, () => {
        this.searchForContentItem(vm, id, callback)
      })
    }
  }
  public initMentioningLinks (vm: any): void {
     jQuery('.voyoa-mention:not([data-visible="false"])').each((index: number, that: HTMLElement): void => {
      if (jQuery(that).parents('.mce-content-body[contenteditable="true"]').length > 0 ) return
      if (jQuery(that).data('initialized') === 'true') return
      else {
        jQuery(that).data('initialized', 'true')
        const nickName = jQuery(that).text()
        if (typeof nickName !== 'undefined') {
          const route = vm.$router.resolve({name: 'publicProfile', params: {nickName: nickName}})
          uiService.setMentionLink(that, nickName, route.href)
        }
      }
    })   
    jQuery('.voyoa-mention[data-visible="false"]').each((index: number, that: HTMLElement): void => {
      if (jQuery(that).data('initialized') === 'true') return
      else {
        jQuery(that).text('deleted-User')        
        jQuery(that).off('click')
      }    
    })
  }
  public notificationsLocator (vm: any) {
    this.getNotificationsState(vm, (data: {[key: string]: number}) => {
      if (typeof data['feed_updated'] !== 'undefined' && !isNaN(data['feed_updated'])) {
        vm.$emit('updateFeedState', {
          count: data['feed_updated']
        })
        vueTemplateService.$emit4AllChildren(vm, 'updateFeedState', {
          count: data['feed_updated']
        })
      }
    })
  }
  public getNotificationsState (vm: any, callback?: (data: any) => void): void {
    this.wait4Login(vm, () => {
      if (sessionStorage.getItem('pendingAccountToDelete') !== 'true') {
        dataService.getContentData4Datasource(vm, globalVar.serviceURL.systemNotifications, {}, (data: any) => {
          const notifications = {}
          for (let i = 0; i < data.results.length; i++) {
            const notificationsArray = data.results[i].fields.items.value
            for (let j = 0; j < notificationsArray.length; j++) {
              notifications[notificationsArray[j].key.value] = parseInt(notificationsArray[j].value.value)
            }
          }
          if (typeof callback === 'function') callback(notifications)
        })
      }
    })
  }
  private wait4Login (vm: any, callback: () => void) {
    if (!vm.$store.state.user.authenticated) {
      setTimeout(() => {
        this.wait4Login(vm, callback)
      }, 1000)
    } else {
      callback()
    }
  }
  public trimHTMLText (text: string): string {
    text = text.trim()
    const regex = /(^(<br ?\/?>)+)|((<br ?\/?>)+$)/g
    return  text.replace(regex, (x: any) => {  return '' }).trim()
  }
  public isAuthenticated (vm: any): boolean {
    return vm.$store.state.user.authenticated
  }
  public isUserPageOwner (vm: any): boolean {
    return this.isAuthenticated(vm) && (vm.$store.state.user.nickName === vm.$router.currentRoute.params.nickName || (vm.$store.state.user.nickName !== vm.$router.currentRoute.params.nickName && vm.$router.currentRoute.path.indexOf('/p/') !== -1))
  }
  public getLikers (list: Array<{[key: string]: any}>): Array<{[key: string]: string}> {
    const r: Array<{[key: string]: string}> = []
    for (let i = 0; i < list.length; i++) {
      r.push({
        nickName: list[i].nickName.value,
        image: list[i].image.value
      })
    }
    return r
  }
  public getScrollendEventName (): string {
    console.info ('USER AGENT: ',navigator.userAgent,'-----WINDOW SCROLLEND: ','onscrollend' in window,'-----WINDOW SCROLL: ','onscroll' in window)
   /* const chromeAgent = navigator.userAgent.indexOf("Chrome") > -1
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1
    if ((chromeAgent) && (safariAgent)) safariAgent = false
    console.info('getScrollendEventName: ',safariAgent ? 'scroll' : 'scrollend')
    return safariAgent ? 'scroll' : 'scrollend' */
    return ('onscrollend' in window) ? 'scrollend' : 'scroll'
  }
  public getCommentFormZIndex (): number {
    if (typeof window['voyoaCommentFormZIndex'] === 'undefined') window['voyoaCommentFormZIndex'] = 200
    window['voyoaCommentFormZIndex'] = window['voyoaCommentFormZIndex'] + 1
    return window['voyoaCommentFormZIndex']
  }
}
export const generalService = new GeneralService()