<template>
  <div class="form-group">
    <label v-if="field.label" v-bind:for="id" v-bind:class="{required:isRequired}" class="input-text">{{field.label}}</label>
    <textarea data-type="longtext"
        class="form-control"
        v-bind:class="{'invalid-backend': isInvalid, 'hidden': useRichTextEditor}"
        v-bind:id="id"
        v-bind:name="field.name"
        v-bind:required="isRequired"
        v-bind:readonly="isReadonly"
        v-bind:data-error-message-selector="errorMessageSelector"
        v-bind:data-error-message="errorMessage"
        v-model="value"
        v-bind:data-reach-text-editor="useRichTextEditor"
        v-bind:data-text-editor-formats="textEditorFormats"
        v-bind:maxlength="maxLength"
        v-bind:data-error-type-message="errorTypeMessage"
        v-bind:placeholder="placeholder"
        @onKeyDown="(e) => onKeyDown(e)"
        v-html="field.value"
    ></textarea>
    <editor v-if="visibledEditor && useRichTextEditor" v-model="editorValue" :init="editorInit" @onBlur="(e) => onBlur(e)" @onKeyDown="(e) => onKeyDown(e)" :id="'tiny-vue_' + id"></editor>
    <div class="error-message" v-bind:id="errorMessageID"></div>
    <div class="error-message backend"><p v-for="message in errorMessages" :key="message">{{getMessageText(message)}}</p></div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { globalVar } from '@/scripts/own/_globalVar'
import { IFormTextarea } from '@/types/app.d'
import { generalService } from '@/scripts/services/GeneralService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import { tinymceHelper } from '@/scripts/own/tinymceHelper'
import Editor from '@tinymce/tinymce-vue'

var tinyMCE

export default Vue.extend({
  name: 'f-textarea',
  components: {
    editor: Editor
  },
  data (): {[key: string]: any} {
    return {
      guid: '',
      value: null,
      editorValue: null,
      editorInit: {
        height: this.contentSkin === 'compact' ? 30 : 300,
        min_height: this.contentSkin === 'compact' ? 30 : 300,
        resize: false,
        menubar: false,
        statusbar: false, // Mentioning VOYOA-888: Statusbar is removed
        branding: false,
        plugins: 'paste',
        allow_unsafe_link_target: true,
        link_context_toolbar: false,
        valid_elements: false,
        paste_as_text: true,
        skin_url: '/resources/vendors/tinymce/skins/ui/oxide',
        toolbar : 'bold italic | bullist numlist | link | undo redo',
        block_formats: 'Paragraph=p;Title=h1;Subtitle=h2;Heading=h3;Subheading=h4;Headline=h5;Subheadline=h6;Blockquote=blockquote;Preformatted=pre;Address=address',
        formats : {
          underline : {inline : 'u'},
          strikethrough : {inline : 'del'}
        },
        body_class: '',
        setup: () => { /**/ },
      },
      visibledEditor: false
    }
  },
  props: {
    field: Object as PropType<IFormTextarea>,
    inline: Boolean,
    placeholder: String,
    onblur: Function,
    contentSkin: String,
    errorMessageRequired: String
  },
  watch: {
    'field.value' (newVal: any, oldVal: any): void {
      if (typeof newVal !== 'undefined' && oldVal !== null) {
        if ((newVal === null) || (this.value === null) || (newVal.toString() !== this.value.toString()) || (newVal.toString() !== oldVal.toString())) {
          this.value = newVal
          this.editorValue =  this.getEditorValue(this.value)
        }
      }
    }
  },
  created (): void {
    if (this.useRichTextEditor) {
      this.value = this.field.value
      this.editorValue = this.getEditorValue(this.field.value)
    }
    if (!this.inline) {
      this.editorInit['content_css'] = '/resources/vendors/tinymce/skins/content/'+ (typeof this.contentSkin !== 'undefined' ? this.contentSkin : 'default') + '/content.min.css'
    }
  },
  updated (): void {
    generalService.log('> Component "f-textarea" before update', this.value)
    if (this.useRichTextEditor) {
      this.value = this.getElementValue(this.editorValue)
      // ToDo: Verginia, wofür ist das?! -> this.editorValue = this.value.replaceAll('<span class="voyoa-mention">','<span class="voyoa-mention" contenteditable="false">').replaceAll('<span class="voyoa-mention" data-visible="false">','<span class="voyoa-mention" contenteditable="false" data-visible="false">')
      
    }
    this.$emit4AllParents('textareaValueChanged', {id: this.id, name: this.field.name, value: this.value})
    generalService.log('> Component "f-textarea" is updated', this.value)
  },
  mounted (): void {
    this.guid = generalService.createGuid()
    this.value = this.field.value
    const mentioningOnly = this.field.format == 'mention' ?  true : false

    this.editorValue = this.getEditorValue(this.field.value)
    this.editorInit.toolbar = false
    this.editorInit.valid_elements = !this.field.format ? false : (mentioningOnly ? this.field.format.replace('mention', 'br,p,span[class|contenteditable|data-visible]') : this.field.format.replace(/\bunderline\b/, 'u').replace('mention', 'p,br,span[class|contenteditable|data-visible]'))
    this.editorInit.body_class = 'textarea'
    this.editorInit.block_formats = !this.field.format ? this.editorInit.block_formats : tinymceHelper.getRrichEditorBlockformats(this.field.format)
    this.editorInit.resize = !this.field.format || mentioningOnly ? true : this.editorInit.resize
    this.editorInit.height = !this.field.format || mentioningOnly ? 30 : this.editorInit.height
    this.editorInit.min_height = !this.field.format || mentioningOnly ? 30 : this.editorInit.min_height
    this.editorInit.menubar = !this.field.format || mentioningOnly ? false : this.editorInit.menubar
    this.editorInit.statusbar = !this.field.format || mentioningOnly ? false : this.editorInit.statusbar

    if (this.placeholder) this.editorInit.placeholder = this.placeholder
    if (typeof this.inline !== 'undefined') this.editorInit.inline = this.inline

    /* [Start] Mentioning VOYOA-888 */

    this.editorInit.setup = (editor: any): void => {


      const onAction = (autocompleteApi, rng, value): void => {
        editor.selection.setRng(rng)
        editor.insertContent(value)
        autocompleteApi.hide()
      }

      editor.ui.registry.addAutocompleter('mentioning', {
        ch: '@',
        minChars: 3,
        columns: 1,
        highlightOn: ['char_name'],
        onAction: onAction,
        fetch: (pattern) => {
          return new Promise((resolve) => {
            jQuery.ajax({
              type: 'GET',
              dataType: 'json',
              url: '/api/v1/users',   
              data: { q: pattern },
              success: (data: any) => {
                resolve(data.map(function (char) {
                  const cImage = char.image ? {
                              type: 'cardimage',
                              src: char.image,
                              alt: char.realName,
                              classes: ['card-item','avatar-picture']
                            } : {
                              type: 'cardtext',
                              text: char.nickName.substring(0,1).toUpperCase(),
                              classes: ['card-item','avatar-picture','no-picture']
                            }
                            
                  return {
                    type: 'cardmenuitem',
                    value: '<span class="voyoa-mention" contenteditable="false">'+char.nickName+'</span> ',
                    label: char.realName,
                    items: [
                        {
                          type: 'cardcontainer',
                          direction: 'horizontal',
                          align: 'left',
                          valign: 'middle',
                          items: [
                            cImage
                            
                          ]
                        },
                        {
                          type: 'cardcontainer',
                          direction: 'vertical',
                          align: 'left',
                          valign: 'middle',
                          items: [                            
                            {
                              type: 'cardtext',
                              text: ' @'+char.nickName,
                              name: 'char_name',
                              classes: ['card-item','nick-name']
                            },                       
                            {
                              type: 'cardtext',
                              text: char.realName ? char.realName : '',
                              name: 'char_name',
                              classes: ['card-item','real-name']
                            }
                          ]
                        }
                    ]
                  }
                })
               )
              }
            })
          })
        }
      }) 
    }    
    /* [End] Mentioning VOYOA-888 */

    if (this.useRichTextEditor) {
      const getTinymceScript = () => import('tinymce/tinymce')
      const getTinymceIcons = () => import('tinymce/icons/default')
      const getTinymceTheme = () => import('tinymce/themes/silver/theme')
      const getTinymcePluginLink = () => import('tinymce/plugins/link')
      const getTinymcePluginLists = () => import('tinymce/plugins/lists')
      const getTinymcePluginTable = () => import('tinymce/plugins/table')
      const getTinymcePluginPaste = () => import('tinymce/plugins/paste')

      getTinymceScript().then((tinymce: any): void => {
        tinyMCE = tinymce
        getTinymceIcons().then(() => {
          getTinymceTheme().then(() => {
            getTinymcePluginLink().then(() => {
              getTinymcePluginLists().then(() => {
                getTinymcePluginTable().then(() => {
                  getTinymcePluginPaste().then(() => {
                    this.visibledEditor = true              
                  })
                })
              })
            })
          })
        })
      })
     
     /*
      window.setTimeout(() => {
        tinymce.init({})
        this.visibledEditor = true
      }, 1)
      */
    }
    this.$nextTick(() => {
      this.$on('setFocusOnTextarea', (e: any): void => {
        if (typeof tinyMCE === 'undefined') return
        const currentEditor = tinyMCE.get('tiny-vue_' + this.id)
        if (currentEditor !== null ) {
          currentEditor.selection.select(currentEditor.getBody(), true);
          currentEditor.selection.collapse(false);
          currentEditor.focus()
        }
      })
      this.$on('afterSendData', (value: any): void => {
        this.value = value
        this.editorValue = value != null ? this.getEditorValue(value) : ''
      })
      this.$on('setTextareaValue', (e: any): void => {
        if ((typeof e.id !== 'undefined' && this.id === e.id) || (typeof e.name !== 'undefined' && this.field.name === e.name)) {
          this.setValue(e.value)
        }
      })
    })
  },
  computed: {
    id (): string {
      return  typeof this.field !== 'undefined' ? 'id_' + this.field.name.replace(/\./g, '_') + '_' + this.guid : 'id_undefined_' + this.guid
    },
    isRequired (): boolean {
      return typeof this.field === 'undefined' ? false : this.field.required
    },
    isReadonly (): boolean {
      return typeof this.field === 'undefined' ? true : this.field.readonly === true
    },
    useRichTextEditor (): string |boolean {
      return this.field.type === 'RICHTEXT' ? 'true' : false
    },
    textEditorFormats (): string | boolean {
      return (typeof this.field.format !== 'undefined' && this.field.format !== '') ? this.field.format : false
    },
    errorMessage (): string {
      return (this.errorMessageRequired) ? this.errorMessageRequired : (this.field.errorMessage === undefined ? 'required' : this.field.errorMessage)
    },
    errorMessageID (): string {
      return 'error_' + this.id
    },
    errorMessageSelector (): string {
      return '#' + this.errorMessageID
    },
    errorMessages (): Array<string> | null {
      var r: Array<string> = []
      if (this.field.messages !== null) {
        for (var i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') r.push(this.field.messages[i].text)
        }
        return r
      } return null
    },
    isInvalid (): boolean {
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (var i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') return true
        }
      } return false
    },
    maxLength (): string | boolean {
      return (this.field.validation && (typeof this.field.validation.Size !== 'undefined') && (typeof this.field.validation.Size.options.max !== 'undefined')) ? this.field.validation.Size.options.max : false
    },
    errorTypeMessage (): string | boolean {
      return (this.field.validation && (typeof this.field.validation.Size !== 'undefined') && (typeof this.field.validation.Size.message !== 'undefined')) ? this.field.validation.Size.message : false
    }
  },
  methods: {
    getMessageText (index: string): string {
      return typeof globalVar.message[index] !== 'undefined' ? globalVar.message[index] : index
    },
    getEditorValue (value: string | null): string {
      return value === null ? '' : value.replaceAll('<span class="voyoa-mention">','<span class="voyoa-mention" contenteditable="false">').replaceAll('<span class="voyoa-mention" data-visible="false">','<span class="voyoa-mention" contenteditable="false" data-visible="false">').replaceAll('<a href=','<a contenteditable="false" href=')
    },
    getElementValue (value: string): string {
      return value.replaceAll('<span class="voyoa-mention" contenteditable="false">','<span class="voyoa-mention">').replaceAll('<span class="voyoa-mention" contenteditable="false" data-visible="false">','<span class="voyoa-mention">').replaceAll('<p>','').replaceAll('</p>','<br/>').replace(/(?:\r\n|\r|\n)/g, '').replaceAll(/(<br( )*\/>){3,}/g,'<br/><br/>').replaceAll('<a contenteditable="false" href=','<a href=')
    },
    onBlur (e: any): void {
      if (typeof this.onblur === 'function') this.onblur(e)
    },
    $emit4AllParents (eventName: string, data: any): void {
      vueTemplateService.$emit4AllParents(this, eventName, data)
    },
    onKeyDown (e: any): void {
      if (e.keyCode === 13 && e.ctrlKey) {
        this.editorValue = this.editorValue.replace(/(?:\r\n|\r|\n)/g, '').trim().replace(/(<p><\/p>)+$/g, '').replace(/(<p \/>)+$/g, '').replace(/(<br( )*\/>)+$/g, '')
        this.editorValue = this.editorValue.trim()
        if (this.editorValue === '') this.editorValue = ' '
      }
      this.$emit4AllParents('textareaKeyDown', {id: this.id, e: e})
    },
    setValue (value: any): void {
      this.value = value
      if (this.useRichTextEditor) {
        this.editorValue = this.getEditorValue(this.value)
      }
    }
  }
})
</script>
<style>
  .tox-tinymce-inline {
    z-index: 100;
  }
  .tox .tox-collection__item-container .card-item {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    line-height: 1em;
    font-family: Poppins-600, Helvetica, Arial, sans-serif;
    color: #55534A;    
  }
  .tox .tox-collection__item-container .card-item.nick-name {
    margin: 2px 5px;
    max-width: 160px;
    min-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1rem;
  }
  .tox .tox-collection__item-container .card-item.real-name {
    margin: 2px 5px;
    max-width: 160px;
    min-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: Poppins-400, Helvetica, Arial, sans-serif;
    font-size: 0.703rem;
    opacity: 0.8;
    line-height: 1rem;
  }
  .tox .tox-collection__item-container .card-item.avatar-picture {
    width: 38px;
    height: 38px;
    margin-top: -1px;
    overflow: hidden;
    border-radius: 100% !important;
    border: 2px solid #ffffff;
    background: #6ebaa0;
  }
  .tox .tox-collection__item-container .card-item.avatar-picture.no-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Poppins-700, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #55544a;
    line-height:15px;
  }
  .tox .tox-autocompleter .tox-collection--list .tox-collection__item--active {
    background: rgb(248, 249, 250);
  }
</style>
