import { cookie } from '@/scripts/own/ait-common'

class AppComponentsStatesService {
  private isQuotaExceededError (err: unknown): boolean {
    return (
      err instanceof DOMException &&
      // everything except Firefox
      (err.code === 22 ||
      // Firefox
      err.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      err.name === "QuotaExceededError" ||
      // Firefox
      err.name === "NS_ERROR_DOM_QUOTA_REACHED")
    )
  }
  public saveViewState (id: string, state: any): void {
    if (!id || !state) return
    let jsonState: {[key: string]: any} = {}
    let f = false

    const savedState = sessionStorage ? sessionStorage.getItem('VIEW-STATE-' + id) : cookie.get('VIEW-STATE-' + id)

    if (savedState) {
      jsonState = JSON.parse(savedState)
      if (jsonState) {
        for (const path in jsonState) {
          if (path === window.location.pathname) {
            jsonState[path] = state
            f = true
          }
        }
      }
    }

    if (!f) jsonState[window.location.pathname] = state
  
    if (sessionStorage) {
      sessionStorage.setItem('VIEW-STATE-' + id, JSON.stringify(jsonState))
    } else {
      cookie.set('VIEW-STATE-' + id, JSON.stringify(jsonState), 0, '/')
    }
  }
  public getSavedViewState (id: string, forPath?: string): {[key: string]: any} | null {
    const mapState = sessionStorage ? sessionStorage.getItem('VIEW-STATE-' + id) : cookie.get('VIEW-STATE-' + id)

    if (!mapState) return null
    
    forPath = typeof forPath === 'undefined' ? window.location.pathname : forPath
    const jsonState = JSON.parse(mapState)

    if (jsonState) {
      for (const path in jsonState) {
        if (path === forPath) {
          return jsonState[path]
        }
      }
    }
    return null
  }
  public deleteSavedViewState (id: string, forPath?: string): void | boolean {
    if (!id) return false
    
    forPath = typeof forPath === 'undefined' ? window.location.pathname : forPath
    let jsonState: {[key: string]: any} = {}

    const savedState = sessionStorage ? sessionStorage.getItem('VIEW-STATE-' + id) : cookie.get('VIEW-STATE-'+id)

    if (savedState) {
      jsonState = JSON.parse(savedState)
      if (jsonState) {
        for (const path in jsonState) {
          if (path === forPath) {
            delete jsonState[path]
          }
        }
      }
    }
    if (sessionStorage) {
      sessionStorage.setItem('VIEW-STATE-' + id, JSON.stringify(jsonState))
    } else {
      cookie.set('VIEW-STATE-' + id, JSON.stringify(jsonState), 0, '/')
    }
  }
  public saveMapState (id: string, state: any): void {
    if (!id || !state) return;
    if (sessionStorage) {
      try {
        sessionStorage.setItem('MAP-STATE-' + id, JSON.stringify(state))
      } catch (err) {
        if (this.isQuotaExceededError(err)) {
          state.points = null
          state.offTrackPoints = null
          state.pointsWithContent = null
          sessionStorage.setItem('MAP-STATE-' + id, JSON.stringify(state))
        } else {
          // Handle the case where the localStorage API is not supported.
        }
      }
    } else {
      cookie.set('MAP-STATE-' + id, JSON.stringify(state), 0, '/')
    }
  }
  public getSavedMapState (id: string): any {
    const mapState = sessionStorage ? sessionStorage.getItem('MAP-STATE-' + id) : cookie.get('MAP-STATE-' + id)
    if (!mapState) return null
    return JSON.parse(mapState)
  }
  public deleteSavedMapState (id: string): void {
    if (sessionStorage) {
      sessionStorage.removeItem('MAP-STATE-' + id)
    } else {
      cookie.set('MAP-STATE-' + id, '', 0, '/')
    }
  }
  public saveFilterState (id: string, filter: {[key: string]: any}): void {
    const state = {}
    for (const index in filter) {
      if (filter[index].value !== null) state[filter[index].name] = filter[index].value
    }
    if (!id || !state) return
    let jsonState = {}
    let f = false
    const savedState = sessionStorage ? sessionStorage.getItem('FILTER-STATE-' + id) :cookie.get('FILTER-STATE-' + id)
    if (savedState) {
      jsonState = JSON.parse(savedState);
      if (jsonState) {
        for (const path in jsonState) {
          if (path === window.location.pathname) {
            jsonState[path] = state
            f = true
          }
        }
      }
    }
    if (!f) jsonState[window.location.pathname] = state
    if (sessionStorage) {
      sessionStorage.setItem('FILTER-STATE-' + id, JSON.stringify(jsonState))
    } else {
      cookie.set('FILTER-STATE-' + id, JSON.stringify(jsonState), 0, '/')
    }
  }
  public getSavedFilterState (id: string): any {
    const filterState = sessionStorage ? sessionStorage.getItem('FILTER-STATE-' + id) : cookie.get('FILTER-STATE-' + id)
    if (!filterState) return null
    const jsonState = JSON.parse(filterState)
    if (jsonState) {
      for (const path in jsonState) {
        if (path === window.location.pathname) {
          return jsonState[path]
        }
      }
    }
    return null;
  }
  public saveReferrerCollectionForActivity (collectionId: string, activityId: string): void {
    if (!collectionId || !activityId) return;
    if (sessionStorage) {
      sessionStorage.setItem('REFERRER-COLLECTION-FOR-ACTIVITY-' + activityId, collectionId)
    } else {
      cookie.set('REFERRER-COLLECTION-FOR-ACTIVITY-' + activityId, collectionId, 0, '/')
    }
  }
  public getReferrerCollectionForActivity (activityId: string): any {
    if (!activityId) return;
    if (sessionStorage) {
      return sessionStorage.getItem('REFERRER-COLLECTION-FOR-ACTIVITY-' + activityId)
    } else {
      return cookie.get('REFERRER-COLLECTION-FOR-ACTIVITY-' + activityId)
    }
  }
  public saveLocalViewState (id: string, nickName: string, state: any): void {
    if (!id || !state || typeof localStorage === 'undefined') return
    let jsonState: {[key: string]: any} = {}
    let uf = false
    let pf = false

    const savedState = localStorage.getItem('VIEW-STATE-' + id)

    if (savedState) {
      jsonState = JSON.parse(savedState)
      if (jsonState) {
        for (const user in jsonState) {
          if (user === nickName) {
            uf = true
            for (const path in jsonState[user]) {
              if (path === window.location.pathname) {
                jsonState[user][path] = state
                pf = true
              }
            }
          }
        }
      }
    }
    if (!uf) {
      jsonState[nickName] = {}
      jsonState[nickName][window.location.pathname] = state
    } else {
      if (!pf) {
        jsonState[nickName][window.location.pathname] = state
      }
    }
    localStorage.setItem('VIEW-STATE-' + id, JSON.stringify(jsonState))
  }
  public getSavedLocalViewState (id: string, nickName: string,): {[key: string]: any} | null {
    if (!id || typeof localStorage === 'undefined') return null
    const state = localStorage.getItem('VIEW-STATE-' + id)
    if (!state) return null
    const jsonState = JSON.parse(state)
    if (jsonState) {
      for (const user in jsonState) {
        if (user === nickName) {
          for (const path in jsonState[user]) {
            if (path === window.location.pathname) {
              return jsonState[user][path]
            }
          }
        }
      }
    }
    return null
  }
  public saveVerticalNavigationState (id: string, state: {[key: string]: any}): void {
    if (!id || !state || !sessionStorage) return
    let jsonState: {[key: string]: any} = {}

    const savedState: any = sessionStorage.getItem('VERTICAL-NAVIGATION-STATE')

    if (savedState) {
      jsonState = JSON.parse(savedState)
      jsonState[id] = state
    } else {
      jsonState = {}
      jsonState[id] = state
    }
    sessionStorage.setItem('VERTICAL-NAVIGATION-STATE', JSON.stringify(jsonState))
  }
  public getSavedVerticalNavigationState (id: string): {[key: string]: any} | null {
    if (!id || !sessionStorage) return null
    let jsonState: {[key: string]: any} = {}

    const savedState: any = sessionStorage.getItem('VERTICAL-NAVIGATION-STATE')

    if (savedState) {
      jsonState = JSON.parse(savedState)
      if (typeof jsonState[id] !== 'undefined') return jsonState[id]
    } 
    return null
  }
  public deleteSavedVerticalNavigationState (id: string): void {
    if (!id || !sessionStorage) return 
    let jsonState: {[key: string]: any} = {}

    const savedState: any = sessionStorage.getItem('VERTICAL-NAVIGATION-STATE')

    if (savedState) {
      jsonState = JSON.parse(savedState)
      if (typeof jsonState[id] !== 'undefined') {
        delete  jsonState[id]
      }
    } 
    sessionStorage.setItem('VERTICAL-NAVIGATION-STATE', JSON.stringify(jsonState))
  }  

}
export const appComponentsStatesService = new AppComponentsStatesService()