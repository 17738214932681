<template>
  <div data-rel="text-with-read-more">
    <div v-if="isShortText(text)" v-html="text"></div>
    <div v-else>
      <div class="short-text"><span v-html="getShortText(text)"></span> <a href="javascript:void(0)" class="read-more" :class="readMoreIconClass" @click="(e) => showText(e)">{{ readMore }}</a></div>
      <div class="whole-text hidden"><span v-html="text"></span> <a href="javascript:void(0)" class="read-more" :class="readLessIconClass" @click="(e) => hideText(e)">{{ readLess }}</a></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import clip from "text-clipper"
import { globalVar } from '@/scripts/own/_globalVar'
import { generalService } from '@/scripts/services/GeneralService'

export default Vue.extend({
  name: 'text-with-read-more',
  props: {
    text: String,
    maxLength: Number,
    readMoreButtonIcon: String,
    readMoreLabel: String,
    readLessButtonIcon: String,
    readLessLabel: String,
    onReadMore: Function,
    onReadLess: Function
  },
  computed: {
    readMoreIconClass (): any {
      return typeof this.readMoreButtonIcon !== 'undefined' ? 'icon ' + this.readMoreButtonIcon : false
    },
    readLessIconClass (): any {
      return typeof this.readLessButtonIcon !== 'undefined' ? 'icon ' + this.readLessButtonIcon : false
    },
    readMore (): string {
      return typeof this.readMoreLabel !== 'undefined' ? this.readMoreLabel : globalVar.view.default.labels.button.readMore
    },
    readLess (): string {
      return typeof this.readLessLabel !== 'undefined' ? this.readLessLabel : globalVar.view.default.labels.button.readLess
    },
    maxTextLength (): number {
      return typeof this.maxLength !== 'undefined' ? this.maxLength : 80
    }
  },
  methods: {
    trimText (text: string): string {
      return generalService.trimHTMLText(text)
    },
    isShortText (text: string): boolean {
      return this.trimText(text).length < this.maxTextLength || (this.trimText(text).length >= this.maxTextLength && this.getShortText(this.trimText(text)).length === this.trimText(text).length)
    },
    getShortText (text: string): string {
      return clip(this.trimText(text), this.maxTextLength, { html: true, maxLines: 3 })
    },
    showText (e: any): void {
      jQuery(e.target).parents('.scroller-vertical').data('scrollInitiator', 'textWithReadMore')
      jQuery(e.target).parents('[data-rel="text-with-read-more"]').find('.short-text').addClass('hidden')
      jQuery(e.target).parents('[data-rel="text-with-read-more"]').find('.whole-text').removeClass('hidden')
      if (typeof this.onReadMore === 'function') this.onReadMore()
    },
    hideText (e: any): void {
      jQuery(e.target).parents('.scroller-vertical').data('scrollInitiator', 'textWithReadMore')
      jQuery(e.target).parents('[data-rel="text-with-read-more"]').find('.whole-text').addClass('hidden')
      jQuery(e.target).parents('[data-rel="text-with-read-more"]').find('.short-text').removeClass('hidden')
      if (typeof this.onReadLess === 'function') this.onReadLess()
    }
  }

})
</script>