<template>
  <div class="search-person-panel" v-bind:class="{'without-scroller': withoutScroller}">
    <div class="search-field-holder" :class="{'without-border': results.length === 0 &&  getSearchResultsFromStorage().length === 0}">
      <input id="person-search" name="q" v-model="q" type="text" :placeholder="headerLabels.searchForm.placeholder" class="form-control" @keydown="(e) => onKeyDown(e)">
      <div class="buttons">
        <a v-if="q" href="javascript:void(0)" class="clear-button iconed-text icon close-small" @click="clearSearchInput()"> </a>
        <a href="javascript:void(0)" class="close-button" @click="closeSearchPanel()">{{ getGlobalLabel('button', 'cancel') }}</a>
      </div>
    </div>
    <div class="results" v-bind:class="{loading: showLoader}">
      <div class="scroller">
        <div class="scroll-panel">
          <div class="scroller-vertical person-list">
            <h4 v-if="results.length > 0" v-html="results.length + ' ' + resultsLabel"></h4>
            <router-link v-for="(item, index) in results" :key="'SR_' + index + '_' + item.nickName + '_' + item.realName" class="results-item item cursor-pointer"  v-bind:to="{name: 'publicProfile', params: {nickName: item.nickName}}" event @click.native.prevent="(e) => onClickCard(e, {nickName: item.nickName, realName: item.realName, image: item.image})">
              <profilePictureThumbnail :linking="false" :nickName="item.nickName" :pictureUrl="item.image"></profilePictureThumbnail>
              <div class="text" v-html="getName(item.nickName, item.realName)"></div>
            </router-link>
            <div v-if="results.length === 0 && getSearchResultsFromStorage().length > 0">
              <h4 v-html="headerLabels.searchForm.lastResults"></h4>
              <router-link v-for="(item, index) in getSearchResultsFromStorage()" :key="'LSR_' + index + '_' + item.nickName + '_' + item.realName" class="results-item"  v-bind:to="{name: 'publicProfile', params: {nickName: item.nickName}}" event @click.native.prevent="(e) => onClickCard(e, {nickName: item.nickName, realName: item.realName, image: item.image})">
                <profilePictureThumbnail :linking="false" :nickName="item.nickName" :pictureUrl="item.image"></profilePictureThumbnail>
                <div class="text" v-html="getName(item.nickName, item.realName)"></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from 'axios'
import { globalVar } from '@/scripts/own/_globalVar'
import { Bus as VuedalsBus } from '@/components/vuedals/main'
import ContentErrorMessage from '@/components/_content_error_message.vue'
import profilePictureThumbnail from '@/components/_profile_picture_thumbnail.vue'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import { uiService } from '@/scripts/services/UIService'

export default Vue.extend({
  name: 'DatasourcePersonSearch',
  components: {
    ContentErrorMessage,
    profilePictureThumbnail
  },
  data (): any {
    return {
      q: '',
      keyDowned: false,
      showLoader: false,
      withoutScroller: true,
      isDataRequestError: null,
      results: []
    }
  },
  computed: {
    headerLabels (): {[key: string]: any} {
      return globalVar.header.labels
    },
    resultsLabel (): string {
      return this.results.length === 1 ? this.headerLabels.searchForm.result : this.headerLabels.searchForm.results
    }
  },
  updated (): void {
    if (this.keyDowned) {
      if (this.q.length >= 3) this.fetchData(globalVar.serviceURL.users, this.q)
      else this.results = []
    }
    this.keyDowned = false

    //set scroller height
    this.$nextTick(() => {
      jQuery('.search-panel .results .scroll-panel > div').height('auto')

      let searchPanelInnerHeight = jQuery('.search-panel').innerHeight()
      if (typeof searchPanelInnerHeight === 'undefined') searchPanelInnerHeight = 0

      let searchPanelHeight = jQuery('.search-panel').height()
      if (typeof searchPanelHeight === 'undefined') searchPanelHeight = 0

      let searchFieldHolderHeight = jQuery('.search-field-holder').innerHeight()
      if (typeof searchFieldHolderHeight === 'undefined') searchFieldHolderHeight = 0

      let windowHeight = jQuery(window).height()
      if (typeof windowHeight === 'undefined') windowHeight = 0

      if (searchPanelInnerHeight !== 0 && searchPanelInnerHeight >= windowHeight) {
        jQuery('.search-panel .results .scroll-panel > div').height(searchPanelHeight - searchFieldHolderHeight)
        this.withoutScroller = false
      } else {
        this.withoutScroller = true
      }
    })
  },
  mounted (): void {
    this.wait4LoadSearchPanel(() => {
      jQuery('#person-search').on('touchstart', function (e: any) {
        jQuery(e.target).focus()
      })
      jQuery('#person-search').trigger('touchstart')

      jQuery(window).on('click', this.onWindowClick)
    })
  },
  destroyed () {
    jQuery(window).off('click', this.onWindowClick)
  },
  methods: {
    fetchData (serviceUrl: string, q?: string): void {
      this.showLoader = true
      axios({
          url: serviceUrl,
          method: 'get',
          params: {
            q: q
          }
        })
        .then((response) => {
          //console.info('axios response !!!', response)
          if (response.status === 200) {
            this.results = []
            for (let i = 0; i < response.data.length; i++) {
              this.results.push(response.data[i])
            }
          }
          this.showLoader = false
        })
        .catch((error) => {
          this.showLoader = false
        })
    },
    onKeyDown (e: any): void {
      this.keyDowned = true
    },
    getName (nickName: string, realName?: string): string {
     // realName = typeof realName !== 'undefined' && realName?.length > 20 ? realName?.substring(0, 20) + '...' : realName
      if (this.q !== '') {
        const regEx = new RegExp('(' + this.q + ')', "ig")
        nickName = nickName.replace(regEx, '<strong>$1</strong>')
        if (typeof realName !== 'undefined' && realName !== '') realName = realName.replace(regEx, '<strong>$1</strong>')
      }
      return realName && realName !== '' ? '<h3>@' + nickName + '</h3><h4>' + realName + '</h4>' : '<h3>@' + nickName + '</h3>'
    },
    getNickName (): string {
      return vueTemplateService.getLoginedUserNickName(this)
    },
    addSearchResultInStorage(item: any): void {
      if (typeof localStorage !== 'undefined') {
        const nickName = this.getNickName()
        let jsonState: {[key: string]: any} = {}
        let uf = false

        const savedState = localStorage.getItem('LAST-SEARCH-RESULTS')
        if (savedState) {
          jsonState = JSON.parse(savedState)
          if (jsonState) {
            for (const user in jsonState) {
              if (user === nickName) {
                uf = true
                if (typeof jsonState[user] === 'object') {
                  let foundedIndex
                  for (let i = 0; i < jsonState[user].length; i++) {
                    if (jsonState[user][i].nickName === item.nickName) {
                      foundedIndex = i
                    }
                  }
                  if (typeof foundedIndex !== 'undefined') jsonState[user].splice(foundedIndex, 1)
                  let r = jsonState[user].slice()
                  r = r.reverse()
                  r.push(item)
                  r = r.reverse()
                  r = r.slice(0, 5)
                  jsonState[user] = r
                }
              }
            }
          }
        }
        if (!uf) {
          jsonState[nickName] = []
          jsonState[nickName].push(item)
        }
        localStorage.setItem('LAST-SEARCH-RESULTS', JSON.stringify(jsonState))
      }
    },
    onClickCard (e: any, item: any): void {
      try {
        VuedalsBus.$emit('dismiss')
      } catch (error: any) {
        console.error(error)
      }
      this.addSearchResultInStorage(item)
      if (e.ctrlKey) {
        const routeData = this.$router.resolve({name: 'publicProfile', params: {nickName: item.nickName}})
        window.open(routeData.href, '_blank')
      } else {
        if (this.$router.currentRoute.name !== 'publicProfile' || (this.$router.currentRoute.name === 'publicProfile' && this.$router.currentRoute.params.nickName !== item.nickName)) this.$router.push({name: 'publicProfile', params: {nickName: item.nickName}})
      }
    },
    getSearchResultsFromStorage(): Array<any> {
      if (typeof localStorage !== 'undefined') {
        const nickName = this.getNickName()
        let jsonState: {[key: string]: any} = {}
        const savedState = localStorage.getItem('LAST-SEARCH-RESULTS')
        if (savedState) {
          jsonState = JSON.parse(savedState)
          if (jsonState) {
            for (const user in jsonState) {
              if (user === nickName) {
                return jsonState[user]
              }
            }
          }
        }
      }
      return []
    },
    closeSearchPanel (): void {
      vueTemplateService.$emit4AllParents(this, 'closeSearchPanel', {})
    },
    onWindowClick (e: any): void {
      if (jQuery(e.target).hasClass('clear-button')) return
      if (jQuery(e.target).parents('.search-panel').length === 0) this.closeSearchPanel()
    },
    clearSearchInput (): void {
      this.q = ''
      this.results = []
    },
    wait4LoadSearchPanel (callback: () => void): void {
      if (jQuery('#person-search').length === 0) {
        setTimeout(() => {
          this.wait4LoadSearchPanel(callback)
        }, 100)
      } else {
        setTimeout(() => {
          callback()
        }, 0)
      }
    },
    getGlobalLabel (type: string, name: string): string {
      return uiService.getGlobalLabel(type, name)
    }
  }
})
</script>
