<template>
  <div id="app" v-bind:class="{ container: pageMode === 'static', 'iframe-mode': isIFrame(), 'widget': pageMode === 'widget'}" data-html2canvas-ignore="true">
    <router-view :key="$route.path + '?' + getQueryString($route.query)"></router-view>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { isIFrame } from '@/scripts/own/ait-common'
import { globalVar } from '@/scripts/own/_globalVar'
import { generalService } from '@/scripts/services/GeneralService'
import html2canvas from 'html2canvas';

export default Vue.extend({
  name: 'App',
  data (): any {
    return {
      pageMode: false
    }
  },
  created (): void {
    generalService.log('View "App.vue" created !')
    this.$on('setViewPortMode', (mode: string): void => {
      switch (mode) {
        case 'static':
        case 'widget': {
          this.pageMode = mode
          break
        }
        default: {
          this.pageMode = 'default'
        }
      }
      
    })
  },
  mounted (): void {
    generalService.log('View "App.vue" mounted !')
    this.keepAlive()
    this.startNotificationLocator()
    window['html2canvas'] = html2canvas
  },
  methods: {
    getQueryString (query: {[key: string]: any}): string {
      return Object.keys(query).map(key => key + '=' + query[key]).join('&')
    },
    isIFrame (): boolean {
      return isIFrame()
    },
    keepAlive (): void {
      setInterval((): void => {
        if (this.$store.state.user.authenticated) {
          var httpRequest = new XMLHttpRequest()
          httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4) {
              this.$store.commit('updateVersionData', { appNGVersion: httpRequest.response.appNGVersion, appVersion: httpRequest.response.appVersion, frontendVersion: httpRequest.response.frontendVersion})
            }
          }
          httpRequest.open('GET', globalVar.serviceURL.keepAlive)
          httpRequest.responseType = "json";
          httpRequest.send(null)
        }
      }, 60000)
    },
    startNotificationLocator (): void {
      setInterval((): void => {
        generalService.notificationsLocator(this)
      }, 60000)
    }
  }
})
</script>

<style>
  @import '../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.min.css';
  @import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css';
  .container.iframe-mode {
    max-width: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .iframe-mode .container {
    max-width: none !important;
  }
  .iframe-mode .tab-box {
    padding-left: 0;
    padding-right: 0;
  }

</style>