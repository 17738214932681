<template>
  <a class="mention-link"
  target="_blank"
  v-text="getLabel"
  v-bind:title="getLabel"
  v-bind:href="getHRef"
  v-tooltip="getTooltip"/>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'mention-link',
  props: {
    label: String,
    href: String,
    tooltip: Object

  },
  computed: {
    getLabel (): string {
        return this.label
    },
    getHRef (): string {
        return this.href
    },
    getTooltip (): any {
      return this.tooltip
    }
  }
})
</script>
<style>
  .tooltip .tooltip-inner {
    color: #55544a;
    background: #efeeed;
    max-width: 300px;
    min-height: auto;
    border-radius: 5px;
    margin-bottom: 0;
    height: 100%;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
  }
  .tooltip .tooltip-arrow {
    border-color: #efeeed;
  }
  .tooltip .card-container {
    width: 100%;
    max-width: 210px;
  }
  .tooltip .card-item {
    display: inline-block;
    margin: 0 0px 0 0;
    text-align: left;
    vertical-align: middle;   
  }
  .tooltip .card-item.w30 {
    width: 44px;
  }
  .tooltip .card-item.w70 {
    width: 160px;
  }
  .tooltip .card-item.w70 div {
    padding: 2px 0;
    font-size: 0.75rem;
    line-height: 1em;
    font-family: Poppins-600, Helvetica, Arial, sans-serif;
    color: #55534A;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .tooltip .card-item.w70 div.real-name {
    font-family: Poppins-400, Helvetica, Arial, sans-serif;
    font-size: 0.703rem;
    opacity: 0.8;
  }

  .tooltip .avatar-picture {
    width: 38px;
    height: 38px;
    margin-top: -1px;
    overflow: hidden;
    border-radius: 100% !important;
    border: 2px solid #ffffff;
    background: #6ebaa0;
  }
  .tooltip .avatar-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 100% !important;
    border: 2px solid #ffffff;
    background: #6ebaa0;
    strong {
      display: inline-block;
      font-size: 18px;
      line-height: 15px;
      height: 18px;
      color: #55544a;
      text-align: center;
      font-family: Poppins-700, Helvetica, Arial, sans-serif;
    }
  }
</style>
