<template>
  <div class="content">
    <ul class="nav">
      <li>
        <router-link class="iconed-text" v-bind:to="{ name: 'createActivity', params: { nickName: nickName }}"><span class="icon activity" v-html="getButtonLabel('createNewActivity')"></span></router-link>
      </li>
      <li>
        <router-link class="iconed-text" v-bind:to="{ name: 'createCollection', params: { nickName: nickName }}"><span class="icon collection" v-html="getButtonLabel('createNewCollection')"></span></router-link>
      </li>
    </ul>
  </div>
  </template>
  
<script lang="ts">
import Vue from 'vue'
import { uiService } from '@/scripts/services/UIService'
  
export default Vue.extend({
  name: 'AddItem',
  props: {
    nickName: String
  },
  methods: {
    getButtonLabel (name: string): string {
      return uiService.getGlobalLabel('button', name)
    }
  }
})
</script>