<template>
  <div class="content">
    <ul class="nav">
      <li>
        <a class="iconed-text" href="javascript:void(0)" @click="onClick('singlePicture')"><span class="icon single-picture-layout" v-html="getButtonLabel('singlePicturePerPage')"></span></a>
      </li>
      <li>
        <a class="iconed-text" href="javascript:void(0)" @click="onClick('twoPictures')"><span class="icon two-pictures-layout" v-html="getButtonLabel('twoPicturesPerPage')"></span></a>
      </li>
    </ul>
  </div>
  </template>
  
<script lang="ts">
import Vue from 'vue'
import { uiService } from '@/scripts/services/UIService'
import { Bus as VuedalsBus } from '@/components/vuedals/main'

export default Vue.extend({
  name: 'SelectPhotoBookLayout',
  props: {
    onSelect: Function
  },
  methods: {
    getButtonLabel (name: string): string {
      return uiService.getGlobalLabel('button', name)
    },
    onClick (layout: string): void {
      if (typeof this.onSelect === 'function') {
        this.onSelect(layout)
        try {
          VuedalsBus.$emit('dismiss')
        } catch (e: any) {
          VuedalsBus.$emit('close')
        }
      }
    }
  }
})
</script>