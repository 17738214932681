<template>
  <div class="form-group mark-container">
    <label v-bind:for="id" v-bind:class="{required:isRequired, 'show-label': showLabel, 'without-animation': !valueChanged, 'focus': focused}" class="input-text">
      <span v-html="field.label"></span>
      <select v-bind:multiple="isMultiple" class="form-select custom-select"
        v-bind:class="{'invalid-backend': isInvalid, 'hidden': isMultiple}"
        v-bind:id="id"
        v-bind:name="fieldName"
        v-model="value"
        data-type="select"
        v-bind:required="isRequired"
        v-bind:readonly="isReadonly"
        v-bind:disabled="isReadonly"
        v-bind:data-error-message-selector="errorMessageSelector"
        v-bind:data-error-message="errorMessage"
        @input="onSelectChange"
        @focus="setFocused(true)"
        @blur="setFocused(false)"
      >
        <fragment v-if="!isMultipleOptionGroups">
          <option v-for="option in field.options.entries" v-bind:value="option.value" v-bind:selected="option.selected" v-bind:key="option.value">
            {{ option.label }}
          </option>
        </fragment>
        <fragment v-if="isMultipleOptionGroups">
          <optgroup v-for="optGroup in field.options.entries" v-bind:label="optGroup.label" v-bind:key="optGroup.label">
            <fragment v-if="optGroup.options">
              <option v-for="option in optGroup.options" v-bind:value="option.value" v-bind:selected="option.selected === true" v-bind:key="option.value">
                {{ option.label }}
              </option>
            </fragment>
            <fragment v-else>
              <option v-bind:value="optGroup.value" v-bind:selected="optGroup.selected === true">
                {{ optGroup.label }}
              </option>
            </fragment>
          </optgroup >
        </fragment>
      </select>
      <multiselect v-if="isMultiple && isMultipleOptionGroups"
        v-bind:class="{'invalid-backend': isInvalid}"
        v-model="multiselectValue"
        v-bind:multiple="true"
        v-bind:close-on-select="false"
        v-bind:clear-on-select="false"
        v-bind:preserve-search="true"
        v-bind:required="isRequired"
        v-bind:readonly="isReadonly"
        v-bind:disabled="isReadonly"
        v-bind:placeholder="getAppEngineLabel('multiSelect', 'placeholder')"
        v-bind:selectLabel="getAppEngineLabel('multiSelect', 'selectLabel')"
        v-bind:selectedLabel="getAppEngineLabel('multiSelect', 'selectedLabel')"
        v-bind:deselectLabel="getAppEngineLabel('multiSelect', 'deselectLabel')"
        v-bind:preselect-first="false"
        v-bind:options="multipleOptions"
        group-values="options"
        group-label="label"
        label="label"
        track-by="label"
        @input="onChange"
        @open="setFocused(true)"
        @close="setFocused(false)"
        >
      </multiselect>
      <multiselect v-if="isMultiple && !isMultipleOptionGroups"
        v-bind:class="{'invalid-backend': isInvalid}"
        v-model="multiselectValue"
        v-bind:multiple="true"
        v-bind:close-on-select="false"
        v-bind:clear-on-select="false"
        v-bind:preserve-search="true"
        v-bind:required="isRequired"
        v-bind:readonly="isReadonly"
        v-bind:disabled="isReadonly"
        v-bind:placeholder="getAppEngineLabel('multiSelect', 'placeholder')"
        v-bind:selectLabel="getAppEngineLabel('multiSelect', 'selectLabel')"
        v-bind:selectedLabel="getAppEngineLabel('multiSelect', 'selectedLabel')"
        v-bind:deselectLabel="getAppEngineLabel('multiSelect', 'deselectLabel')"
        v-bind:preselect-first="false"
        v-bind:options="multipleOptions"
        v-bind:custom-label="customLabel"
        @input="onChange"
        @open="setFocused(true)"
        @close="setFocused(false)"
        >
      </multiselect>
    </label>
    <input v-if="isReadonly" type="hidden" v-bind:name="field.name" v-bind:value="field.value" data-multiple-value="true" >
    <div class="error-message" v-bind:id="errorMessageID"></div>
    <div class="error-message backend"><p v-for="message in errorMessages" v-bind:key="message">{{getMessageText(message)}}</p></div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { globalVar } from '@/scripts/own/_globalVar'
import { generalService } from '@/scripts/services/GeneralService'
import { IFormSelect } from '@/types/app.d'
import Multiselect from 'vue-multiselect'

export default Vue.extend({
  name: 'f-select',
  components: {
    multiselect: Multiselect
  },
  data (): {[key: string]: any} {
    return {
      guid: '',
      value: null,
      multiselectValue: [],
      valueChanged: false,
      focused: false
    }
  },
  props: {
    field: Object as PropType<IFormSelect>,
    errorMessageRequired: String,
    readonly: Boolean,
    required: Boolean
  },
  created (): void {
    this.guid = generalService.createGuid()
    this.value = this.field.value
    if (this.isMultiple) {
      for (let i = 0; i < this.field.options.entries.length; i++) {
        if (typeof this.field.options.entries[i].options === 'undefined') {
          if (this.field.options.entries[i].selected) {
            if (this.isMultipleOptionGroups) {
              this.multiselectValue.push({
                label: this.field.options.entries[i].label,
                value: this.field.options.entries[i].value
              })
            } else {
              this.multiselectValue.push(this.field.options.entries[i].value)
            }
          }
        } else { // option groups
          const options = this.field.options.entries[i].options
          if (typeof options !== 'undefined') {
            for (let j = 0; j < options.length; j++) {
              if (options[j].selected) this.multiselectValue.push(options[j])
            }
          }
        }
      }
    }
    // console.info('!!!!! multiselectValue', this.multiselectValue)
  },
  watch: {
    'field.value' (newVal: any): void {
      if ((typeof newVal !== 'undefined') && (typeof this.value !== 'undefined')) {
        if ((newVal === null) || (this.value === null) || (newVal.toString() !== this.value.toString())) {
          this.value = newVal
          this.multiselectValue = newVal
        }
      }
    }
  },
  computed: {
    id (): string {
      return  typeof this.field !== 'undefined' ? 'id_' + this.field.name.replace(/\./g, '_') + '_' + this.guid : 'id_undefined_' + this.guid
    },
    fieldName (): string {
      const fieldName = typeof this.field !== 'undefined' ? this.field.name : 'undefined'
      return !this.isReadonly ? fieldName : (fieldName + '_disabled')
    },
    isRequired (): boolean {
      return typeof this.field === 'undefined' ? false : (typeof this.required === 'undefined' ? this.field.required : this.required)
    },
    isReadonly (): boolean {
      return typeof this.field === 'undefined' ? true : (typeof this.readonly === 'undefined' ? this.field.readonly : this.readonly)
    },
    errorMessage (): string {
      return (this.errorMessageRequired) ? this.errorMessageRequired : (this.field.errorMessage === undefined ? 'required' : this.field.errorMessage)
    },
    errorMessageID (): string {
      return 'error_' + this.id
    },
    errorMessageSelector (): string {
      return '#' + this.errorMessageID
    },
    errorMessages (): Array<string> | null {
      const r: Array<string> = []
      if (this.field.messages !== null) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') r.push(this.field.messages[i].text)
        }
        return r
      } return null
    },
    isInvalid (): boolean {
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') return true
        }
      } return false
    },
    isMultiple (): boolean {
      return this.field.options.multiple
    },
    isMultipleOptionGroups (): boolean {
      let groupFound = false
      if (this.field.options.multiple && this.field.options.entries.length > 0) {
        for (let i = 0; i < this.field.options.entries.length; i++) {
          if (typeof this.field.options.entries[i].options !== 'undefined') groupFound = true
        }
      }
      return groupFound
    },
    multipleOptions (): Array<any> {
      const r: Array<any> = []
      for (let i = 0; i < this.field.options.entries.length; i++) {
        if (typeof this.field.options.entries[i].options !== 'undefined') {
          r.push({
            label: this.field.options.entries[i].label,
            options: this.field.options.entries[i].options
          })
        } else if (typeof this.field.options.entries[i].value !== 'undefined') {
          if (this.isMultipleOptionGroups) {
            // init empty group
            if (r.length > 0) {
              if (r[0].label !== null) {
                r.splice(0, 0, {
                  label: null,
                  options: []
                })
              }
            } else {
              r[0] = {
                label: null,
                options: []
              }
            }
            r[0].options.push({
              label: this.field.options.entries[i].label,
              value: this.field.options.entries[i].value
            })
          } else {
            r.push(this.field.options.entries[i].value)
          }
        }
      }
      // console.info('>>>> multipleOptions', r)
      return r
    },
    multipleLabels (): {[key: string]: any} {
      const r: {[key: string]: any} = {}
      for (let i = 0; i < this.field.options.entries.length; i++) {
        if (typeof this.field.options.entries[i].value !== 'undefined') {
          r[this.field.options.entries[i].value] = this.field.options.entries[i].label
        }
      }
      return r
    },
    optionsHits (): {[key: string]: any} {
      const r: {[key: string]: any} = {}
      for (let i = 0; i < this.field.options.entries.length; i++) {
        if (typeof this.field.options.entries[i].value !== 'undefined' && typeof this.field.options.entries[i].hits !== 'undefined') {
          r[this.field.options.entries[i].value] = this.field.options.entries[i].hits
        }
      }
      return r
    },
    selectedOptionText (): string {
      let r = ''
      this.field.options.entries.forEach((option: {[key: string]: any}) => {
        if (option.value === this.value) r = option.label
      })
      return r
    },
    showLabel (): boolean {
      if (this.isMultiple) return !!this.value
      else {
        return !(this.value === null || (this.value === '' && this.selectedOptionText === ''))
      }
    }
  },
  methods: {
    getMessageText (index: string): string {
      return typeof globalVar.message[index] !== 'undefined' ? globalVar.message[index] : index
    },
    customLabel (value: any): string {
      if (typeof this.multipleLabels[value] !== 'undefined') return this.multipleLabels[value] + (typeof this.optionsHits[value] !== 'undefined' ? ' ('+this.optionsHits[value]+')' : '')
      else return `${value}`
    },
    onSelectChange (): void {
      this.valueChanged = true
    },
    onChange (value: any): void {
      this.value = []
      for (let i = 0; i < this.multiselectValue.length; i++) {
        if (typeof this.multiselectValue[i].value === 'undefined') {
          this.value.push(this.multiselectValue[i])
        } else {
          this.value.push(this.multiselectValue[i].value)
        }
      }
      this.$props.field.value = this.value
    },
    setFocused (val: boolean): void {
      this.focused = val
    },
    getAppEngineLabel (type: string, index: string): string {
      return typeof globalVar.appEngine.labels[type] !== 'undefined' && typeof globalVar.appEngine.labels[type][index] !== 'undefined' ? globalVar.appEngine.labels[type][index] : index
    },
  }
})
</script>
