import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import jQuery from 'jquery'
import 'bootstrap/dist/js/bootstrap.bundle'
import './sass/main.scss'
import { globalVar } from './scripts/own/_globalVar'
import Fragment from 'vue-fragment'
import ActionPopup from './components/ActionPopup.vue'
import ActionUpdatePointsPositions from './components/ActionUpdatePointsPositions.vue'
import ActionDeletePoints from './components/ActionDeletePoints.vue'
import ActionMergePoints from './components/ActionMergePoints.vue'
import ActionUpdatePointPosition from './components/ActionUpdatePointPosition.vue'
import ActionResizeActivity from './components/ActionResizeActivity.vue'
import ActionRequestFriendship from './components/ActionRequestFriendship.vue'
import communicationLoader from './components/_communication-loader.vue'
import mentionLink from './components/_mention-link.vue'
import addItem from './components/_add-item.vue'
import appLinks from './components/_app-links.vue'
import selectPhotoBookLayout from './components/_select-photo-book-layout.vue'
import VueLazyload from 'vue-lazyload'
import VTooltip from 'v-tooltip'
import { ProcessObserver, isMobile, isTouchDevice, getCurrentBreakpoint } from '@/scripts/own/ait-common'
import { uiService } from '@/scripts/services/UIService'
import { dataService } from './scripts/services/DataService'
import { generalService } from './scripts/services/GeneralService'

Vue.use(Fragment.Plugin)
Vue.use(VTooltip)
Vue.use(VueLazyload,{
  preLoad: 1.3,
  error: globalVar.appEngine.images.errorLoadImage,
  loading: globalVar.appEngine.images.imageLoader,
  attempt: 1
})

Vue.config.productionTip = false

Vue.component('ActionPopup', ActionPopup)
Vue.component('ActionUpdatePointsPositions', ActionUpdatePointsPositions)
Vue.component('ActionDeletePoints', ActionDeletePoints)
Vue.component('ActionMergePoints', ActionMergePoints)
Vue.component('ActionUpdatePointPosition', ActionUpdatePointPosition)
Vue.component('ActionResizeActivity', ActionResizeActivity)
Vue.component('ActionRequestFriendship', ActionRequestFriendship)
Vue.component('communication-loader', communicationLoader)
Vue.component('mention-link', mentionLink)
Vue.component('add-item', addItem)
Vue.component('app-links', appLinks)
Vue.component('select-photo-book-layout', selectPhotoBookLayout)

declare global {
  interface Array<T> {
    findLastIndex(
      predicate: (value: T, index: number, obj: T[]) => unknown,
      thisArg?: any
    ): number
  }
}

declare global {
  interface Window {
    jQuery(arg: any): JQuery;
    L: any;
    html2canvas: any;
  }
}
window.jQuery = jQuery

declare global {
  interface Array<T> {
      remove(o: T): Array<T>;
  }
}
Array.prototype.remove = function(...args) {
  let what: any
  const a = args
  let L = a.length
  let ax: number
  while (L && this.length) {
      what = a[--L]
      while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1)
      }
  }
  return this
}
let frontendAppChangedMessageShowed = false
const app = {
  init (): any {
    jQuery(window).on('scroll', (e: any): void => {
      if (jQuery(window).scrollTop() !== 0) {
        jQuery('header').addClass('highlight')
      } else {
        jQuery('header').removeClass('highlight')
      }
    })
    const processObserverResizeEnd = new ProcessObserver()
    jQuery(window).on('resize', (e: any): void => {
      processObserverResizeEnd.inProcess()
      processObserverResizeEnd.start((): void => {
        jQuery(window).trigger('resizeend')
        this.setWindowOrientation()
        this.setBreakpointClass()
        uiService.setLeftPanelSizing()
        uiService.initNavTabs()
        uiService.initCommentsPanel4ShortLeftPanel()
        setTimeout(() => {
          uiService.initVerticalScrolling()
        }, 250)
      }, 100)
    })
    jQuery(window).on('frontendAppChanged', (e: any): void => {
      if (!frontendAppChangedMessageShowed) {
        uiService.showMessage(globalVar.appEngine.labels.applicationHasBeenUpdated.headline, globalVar.appEngine.labels.applicationHasBeenUpdated.text, null, 'sm')
        frontendAppChangedMessageShowed = true
      }
    })
    if (isMobile.any()) {
      jQuery('html').addClass('mobile')
    } else {
      jQuery('html').addClass('desktop')
    }
    if (isMobile.iPad()) {
      jQuery('html').addClass('ipad')
    }
    if (isTouchDevice) jQuery('html').addClass('touch')
    this.setWindowOrientation()
    this.setBreakpointClass()
    router.beforeEach((to, from, next) => {
      store.state.router.from = from
      next()
    })

    jQuery(window).on('click', (e: any) => {
      if (!(jQuery(e.target).parent().hasClass('has-tooltip') || jQuery(e.target).hasClass('has-tooltip'))) jQuery('.has-tooltip.v-tooltip-open').trigger('click')
    })

    return new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  },
  setWindowOrientation (): void {
    const jWindow: any = jQuery(window)
    if (typeof jWindow !== 'undefined') {
      if (jWindow.width() >= jWindow.height()) {
        jQuery('html').addClass('landscape').removeClass('portrait')
      } else {
        jQuery('html').addClass('portrait').removeClass('landscape')
      }
    }
  },
  setBreakpointClass (): void {
    const currentBreakpoint = getCurrentBreakpoint()
    if (currentBreakpoint !== null) jQuery('html').attr('data-breakpoint', currentBreakpoint.name)
  }
}

dataService.fetchLocalizationContent(generalService.getLocale(), (data) => {
  if (data) $.extend(true, globalVar, data.globalVar)
})

jQuery(() => {
  app.init()
})
