<template>
  <div>
    <div class="page pt-0">
      <div class="pe-2 min-h-100 d-flex flex-column justify-content-between">
        <div class="main-content">
          <div class="centered">
            <div>
              <div class="logo"><img :src="getLogoImage()"></div>
              <h2 class="text-center pt-3">One moment please ...</h2>
            </div>
          </div>
        </div>
      </div>
      <app-footer style="position:absolute; bottom: 0; width: 100%;"/>
      <vuedal></vuedal>
    </div>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXPageGeneral from '@/components/mixins/pageGeneral'
import { globalVar } from '@/scripts/own/_globalVar'
import { generalService } from '@/scripts/services/GeneralService'
import { dataService } from '@/scripts/services/DataService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import appFooter from '@/components/_footer.vue'

export default mixins(MXPageGeneral).extend({
  name: 'Login',
  components: {
    appFooter
  },
  created (): void {
    this.$nextTick((): void => {
      generalService.log('View "Imprint.vue" created')
      vueTemplateService.$emit4AllParents(this, 'setViewPortMode', 'default')
    })
  },  
  mounted (): void {
    generalService.log('View "LoginPage.vue" mounted')
    if (this.isAuthenticated) {
      this.$router.replace({name: 'dashboard'})
    } else {
      const sourceURL = dataService.getServiceUrl(this, 'login')
      if (sourceURL) {
        dataService.getContentData4Action(this, sourceURL, {}, (data: any) => {
          if (data.userdata.authenticated) {
            this.$router.push({name: 'login'})
          } else {
            this.$router.push({name: 'about'})
          }
        })
      }
    }
  },
  methods: {
    getLogoImage (): string {
      return globalVar.appEngine.images.logoBeta
    }
  }
})
</script>
