<template>
  <div v-if="id != null" class="action wrapper" v-bind:class="{ loading: showLoader }">
    <form v-bind:action="executeUrl" v-bind:name="id" v-bind:id="formId" enctype="multipart/form-data" class="form-framework" v-bind:data-locale="locale" v-bind:data-sourceid="id" data-send-method="ajax">
      <div class="modal-body">
        <div v-if="labelsEnabled" class="text-center mb-0" v-html="confirmationText"></div>
        <fragment v-for="fieldItem in field" v-bind:key="fieldItem.name">
          <input v-if="fieldItem.name !== 'ids'" type="hidden" v-bind:name="fieldItem.name" v-bind:value="fieldItem.value">
          <fragment v-if="(fieldItem.name === 'ids') && parameters.pointsId">
            <div v-for="(value, key) in parameters.pointsId" v-bind:key="key + '_' + value">
              <input type="hidden" name="ids" v-bind:value="value">
            </div>
          </fragment>
        </fragment>
        <div v-for="(value, key) in params" v-bind:key="value">
          <input type="hidden" v-bind:name="key.toString()" v-bind:value="value">
        </div>
      </div>
      <div v-if="labelsEnabled" class="modal-footer pb-0">
        <button type="button" class="btn btn-secondary" @click="cancel" v-html="labels.button.cancel"></button>
        <button type="submit" class="btn btn-primary" v-html="labels.button.submit"></button>
      </div>
      <input type="hidden" name="form_action" :value="originId">
    </form>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXActionPopup from '@/components/mixins/actionPopup'
import { Bus as VuedalsBus } from '@/components/vuedals/main'
import { dataService } from '@/scripts/services/DataService'
import { generalService } from '@/scripts/services/GeneralService'
import ContentErrorMessage from '@/components/_content_error_message.vue'

export default mixins(MXActionPopup).extend({
  name: 'ActionMergePoints',
  components: {
    ContentErrorMessage
  },
  methods: {
    fetchData (): void {
      if (this.componentSourceURL) {
        const params = this.parameters ? this.parameters : {}
        // we have to pass the activity ID that the action can check if executor is also the owner
        dataService.getContentData4Action(this, this.componentSourceURL, { activityId: params.activityId }, (data: any) => {
          dataService.updateComponentData(this, data)
        })
      }
    }
  }
})
</script>
