<template>
  <div>
    <label v-bind:class="{required:isRequired}" class="radio" v-html="label"></label>
    <div class="form-group radio mark-container">
      <div v-for="(option, index) in field.options.entries" v-bind:key="option.value" class="form-check" v-bind:data-checked="option.selected" v-bind:class="{'info-tooltip':hasTooltips}">
        <input type="radio"
          data-type="radio"
          class="form-check-input"
          v-bind:class="{'invalid-backend': isInvalid}"
          v-bind:name="field.name"
          v-bind:value="option.value"
          v-model="value"
          v-bind:id="id+index"
          v-bind:checked="option.selected"
          v-bind:required="isRequired"
          v-bind:readonly="isReadonly"
          v-bind:disabled="isReadonly && !option.selected"
          v-bind:data-error-message-selector="errorMessageSelector"
          v-bind:data-error-message="errorMessage"
          v-on:change="onChangeValue"
        >
        <label class="form-check-label" v-bind:for="id+index"><span v-if="hasIcons" class="icon" v-bind:class="getIcon(icons, option.value)"></span> {{option.label}} <span v-if="hasTooltips" class="info-icon" v-tooltip="getTooltipText(tooltips, option.value)" v-bind:title="getTitle(tooltips, option.value)" @click="onClickInfo" v-html="getInfoIcon()"></span></label>
      </div>
    </div>
    <div class="error-message" v-bind:id="errorMessageID"></div>
    <div class="error-message backend"><p v-for="message in errorMessages" v-bind:key="message">{{getMessageText(message)}}</p></div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import jQuery from 'jquery'
import '@/scripts/own/jquery.string'
import { globalVar } from '@/scripts/own/_globalVar'
import { IFormRadio } from '@/types/app.d'
import { generalService } from '@/scripts/services/GeneralService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'

export default Vue.extend({
  name: 'f-input-radio',
  data (): {[key: string]: any} {
    return {
      guid: '',
      value: null
    }
  },
  props: {
    field: Object as PropType<IFormRadio>,
    onChange: Function,
    tooltips: Object as PropType<{[key: string]: any}>,
    icons: Object as PropType<{[key: string]: any}>,
    errorMessageRequired: String
  },
  updated (): void {
    this.$emit4AllParents('RadioButtonUpdated', this)
  },
  mounted (): void {
    this.guid = generalService.createGuid()
    this.value = this.field.value
  },
  watch: {
    field: {
      handler (val: any): void {
        if ((typeof val !== 'undefined') && (this.value !== val.value)) {
          this.value = val.value
          for (var i = 0; i < val.options.entries.length; i++) {
            val.options.entries[i].selected = val.options.entries[i].value === val.value ? true : false
          }
        }          
      },
      deep: true
    }
  },
  computed: {
    id (): string {
      return  typeof this.field !== 'undefined' ? 'id_' + this.field.name.replace(/\./g, '_') + '_' + this.guid : 'id_undefined_' + this.guid
    },
    label (): string {
      return typeof this.field !== 'undefined' ? this.field.label : ''
    },
    checked (): boolean {
      return typeof this.field !== 'undefined' ? this.field.value !== '' : false
    },
    isRequired (): boolean {
      return typeof this.field === 'undefined' ? false : this.field.required
    },
    isReadonly (): boolean {
      return typeof this.field === 'undefined' ? true : this.field.readonly
    },
    hasIcons (): boolean {
      return typeof this.icons !== 'undefined' && this.icons !== null ? true : false
    },
    hasTooltips (): boolean {
      return typeof this.tooltips !== 'undefined' && this.tooltips !== null ? true : false
    },
    errorMessage (): string {
      return (this.errorMessageRequired) ? this.errorMessageRequired : (this.field.errorMessage === undefined ? 'required' : this.field.errorMessage)
    },
    errorMessageID (): string {
      return 'error_' + this.id
    },
    errorMessageSelector (): string {
      return '#' + this.errorMessageID
    },
    errorMessages (): Array<string> | null {
      const r: Array<string> = []
      if (this.field.messages !== null) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') r.push(this.field.messages[i].text)
        }
        return r
      } return null
    },
    isInvalid (): boolean {
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') return true
        }
      } return false
    }
  },
  methods: {
    onChangeValue (e: any): void {
      // console.log('>>> onChangeValue Radio', e.target.value)
      this.value = e.target.value
      if (typeof this.onChange === 'function') {
        this.onChange(this.value)
      }
    },
    getMessageText (index: string): string {
      return typeof globalVar.message[index] !== 'undefined' ? globalVar.message[index] : index
    },
    getTooltipText(tooltips: {[key: string]: any}, label: string): string | object {
      if (typeof label !== 'undefined' && label !== '' && tooltips !== null && typeof this.tooltips !== 'undefined') {
        return {content: tooltips[label.toUpperCase()], trigger: 'click'}
      } else return ''
    },
    getIcon (icons: {[key: string]: any}, label: string): string | object {
      if (typeof label !== 'undefined' && label !== '' && icons !== null && typeof this.icons !== 'undefined') {
        return icons[label.toUpperCase()]
      } else return ''
    },
    getTitle(tooltips: {[key: string]: any}, label: string): string {
      if (typeof label !== 'undefined' && label !== '' && tooltips !== null && typeof this.tooltips !== 'undefined') {
        return tooltips[label.toUpperCase()]
      } else return ''
    },
    onClickInfo(e :any): any {
      jQuery('.has-tooltip.v-tooltip-open').trigger('click')
      e.preventDefault()
      return false
    },
    getButtonLabel (name: string): string {
      return (this.labels !== null) && (typeof this.labels.button !== 'undefined') && (typeof this.labels.button[name] !== 'undefined') ? this.labels.button[name] : (name.charAt(0).toUpperCase() + name.slice(1))
    },
    $emit4AllParents (eventName: string, data: any): void {
      vueTemplateService.$emit4AllParents(this, eventName, data)
    },
    getInfoIcon(): string {
      return globalVar.appEngine.images.infoIcon
    }
  }
})
</script>
